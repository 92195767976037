import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import MealPlannerRecipeCard from '../components/MealPlannerRecipeCard';
import Skeleton from '@material-ui/lab/Skeleton';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import BackupIcon from '@material-ui/icons/Backup';
import TodayIcon from '@material-ui/icons/Today';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FirebaseContext, AuthUserContext } from '../firebase';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { eachDayOfInterval, parse, format } from 'date-fns';
import DateRangePicker from '../components/DateRangePicker';
import * as ROUTES from '../constants/routes';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
    root: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        paddingRight: theme.spacing(1),
        marginTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'row',
        paddingBottom: '72px',
        height: 'calc(100vh - 72px)',
        overflow: 'hidden',
    },
    skeletonContainer: {
        marginTop: theme.spacing(2),
    },
    skeleton: {
        margin: theme.spacing(1),
    },
    searchBox: {
        marginBottom: 0,
    },
    search: {
        width: '100%',
    },
    recipeList: {
        height: '100%',
        overflowY: 'scroll',
    },
    recipeCardDiv: {
        margin: theme.spacing(1),
    },
    daysContainerParent: {
        height: '100%',
        overflow: 'auto'
    },
    daysContainer: {
        height: '100%',
        display: 'flex',
        flexWrap: 'nowrap',
    },
    dayColumn: {
        height: '100%',
        flexShrink: 0,
        flexGrow: 1,
        textAlign: 'center',
    },
    dayColumnDiv: {
        height: '100%',
        minHeight: '150px',
        paddingBottom: theme.spacing(1),
    },
    dayHeader: {
        padding: theme.spacing(1),
        whiteSpace: "pre-wrap",
        wordWrap: 'break-word',
    },
    appBar: {
        top: 'auto',
        bottom: 0,
    },
    grow: {
        flex: 1,
    },
    bottomButtons: {
        marginLeft: theme.spacing(2),
    },
}));

function MealPlanner() {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [recipes, setRecipes] = useState([]);
    const [search, setSearch] = useState([]);
    const [searchText, setSearchText] = useState("");
    const firebase = useContext(FirebaseContext);
    const authContext = useContext(AuthUserContext);
    const location = useLocation();
    const history = useHistory();
    const [lastVisible, setLastVisible] = useState(null);
    const [moreData, setMoreData] = useState(true);
    const [dayRecipes, setDayRecipes] = useState({});
    const [open, setOpen] = useState(false);
    const [dateRange, setDateRange] = useState({ from: null, to: null })

    let pageLimit = 25;

    useEffect(() => {
        let dateArray = location.state.dateArray;
        let passedDayRecipes = location.state.dayRecipes;
        if (dateArray) {
            let tempDayRecipes = Object.fromEntries(dateArray.map(dt => [dt, []]));
            setDayRecipes(tempDayRecipes);
            let tempDateRange = { from: dateToObj(dateArray[0]), to: dateToObj(dateArray[dateArray.length - 1]), enteredTo: dateToObj(dateArray[dateArray.length - 1]) }
            setDateRange(tempDateRange);
        } else if (passedDayRecipes) {
            setDayRecipes(passedDayRecipes);
            let dayKeys = Object.keys(passedDayRecipes);
            let tempDateRange = { from: dateToObj(dayKeys[0]), to: dateToObj(dayKeys[dayKeys.length - 1]), enteredTo: dateToObj(dayKeys[dayKeys.length - 1]) }
            setDateRange(tempDateRange);
        }

    }, [location.state])

    useEffect(() => {
        setLoading(true);
        setMoreData(true);
        document.title = "Meal Planner"
        let recipesRef = firebase.db.collection('recipes')
            .where('authorUID', '==', authContext.authUser.uid)
            .orderBy('title')
            .limit(pageLimit);
        recipesRef.get()
            .then(querySnapshots => {
                let last = querySnapshots.docs[querySnapshots.docs.length - 1];
                setLastVisible(last);
                if (typeof last === "undefined") {
                    setMoreData(false);
                }
                let recipeList = [];
                querySnapshots.forEach(doc => {
                    let recipe = doc.data();
                    recipe.id = doc.id;
                    recipe.options = {servings: 1, leftovers: false};
                    recipeList.push(recipe);
                });
                setRecipes(recipeList);
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
            });
    }, [firebase.db, authContext.authUser.uid, pageLimit]);

    const fetchMoreData = useCallback(() => {
        if (moreData && (lastVisible !== null || typeof lastVisible !== "undefined")) {
            let recipesRef = firebase.db.collection('recipes')
                .where('authorUID', '==', authContext.authUser.uid)
                .orderBy('title')
                .startAfter(lastVisible)
                .limit(pageLimit);
            recipesRef.get()
                .then(querySnapshots => {
                    let last = querySnapshots.docs[querySnapshots.docs.length - 1];
                    setLastVisible(last);
                    if (typeof last === "undefined") {
                        setMoreData(false);
                    }
                    let recipeList = recipes;
                    querySnapshots.forEach(doc => {
                        let recipe = doc.data();
                        recipe.id = doc.id;
                        recipe.options = {servings: 1, leftovers: false};
                        recipeList.push(recipe);
                    });

                    setRecipes(recipeList);
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }, [moreData, lastVisible, recipes, authContext.authUser.uid, firebase.db, pageLimit])

    const loader = useCallback(node => {
        if (node !== null) {
            new IntersectionObserver(
                entries => {
                    entries.forEach(entry => {
                        //console.log(entry)
                        if (entry.isIntersecting) {
                            fetchMoreData();
                        }
                    });
                },
                { threshold: 0.9, root: null }
            ).observe(node);
        }
    }, [fetchMoreData]);

    const SkeletonCard = () => (
        <div className={classes.skeletonContainer}>
            <Skeleton className={classes.skeleton} variant='rect' height={140} />
            <Skeleton className={classes.skeleton} variant='rect' />
            <Skeleton className={classes.skeleton} variant='rect' width='60%' />
        </div>
    );

    function onDragEnd(result) {
        const { source: droppableSource, destination: droppableDestination } = result;

        // dropped outside the list
        if (!droppableDestination) {
            //console.log('dropped outside of any destination');
            return;
        }

        const sourceID = droppableSource.droppableId;
        const destID = droppableDestination.droppableId;

        if ((sourceID === destID) && sourceID === 'recipeList') {
            //do nothing
            //console.log('moved from recipeList to recipeList')
        } else if ((sourceID === destID) && sourceID !== 'recipeList') {
            //console.log('moved from day to same day');
            //Reorder within same day
            const dayRecipeList = Array.from(dayRecipes[sourceID]);
            const [removed] = dayRecipeList.splice(droppableSource.index, 1);
            dayRecipeList.splice(droppableDestination.index, 0, removed);
            setDayRecipes({ ...dayRecipes, [destID]: dayRecipeList })
        } else if ((sourceID !== destID) && sourceID === 'recipeList') {
            //move from recipeList to a day
            //console.log('moved from recipeList to day');
            const sourceClone = Array.from(recipes).filter(recipe => search.every(s => recipe.searchable.includes(s.toLowerCase())));
            const destClone = Array.from(dayRecipes[destID]);
            const [removed] = _.cloneDeep(sourceClone.splice(droppableSource.index, 1));
            if (!Object.values(destClone).map(value => value.id).includes(removed.id)) {
                destClone.splice(droppableDestination.index, 0, removed);
                setDayRecipes({ ...dayRecipes, [destID]: destClone });
            }
        } else if ((sourceID !== destID) && destID === 'recipeList') {
            //move from a day to recipeList
            //console.log('moved from day to recipeList');
            const sourceClone = Array.from(dayRecipes[sourceID]);
            sourceClone.splice(droppableSource.index, 1);
            setDayRecipes({ ...dayRecipes, [sourceID]: sourceClone })
        } else if ((sourceID !== destID)) {
            //move between days
            //console.log('moved from day to different day');
            const sourceClone = Array.from(dayRecipes[sourceID]);
            const destClone = Array.from(dayRecipes[destID]);
            const [removed] = sourceClone.splice(droppableSource.index, 1);
            if (!Object.values(destClone).map(value => value.id).includes(removed.id)) {
                destClone.splice(droppableDestination.index, 0, removed)
                setDayRecipes({ ...dayRecipes, [sourceID]: sourceClone, [destID]: destClone })
            }
        }

    }

    const convertDate = (day) => {
        return format(parse(day, 'MM/dd/yyyy', new Date()), 'EEE M/d');
    }

    const dateToObj = (day) => {
        return parse(day, 'MM/dd/yyyy', new Date())
    }

    const handleClose = () => {
        setOpen(false);
    }

    const onSubmitDate = () => {
        let dateArray = eachDayOfInterval({ start: dateRange.from, end: dateRange.to });
        dateArray = dateArray.map(dt => format(dt, 'MM/dd/yyyy')); //returns ex: 4/8/1998
        let newDayRecipes = Object.fromEntries(dateArray.map(dt => [dt, []]));
        for (let key of dateArray) {
            if (dayRecipes[key]) {
                newDayRecipes[key] = dayRecipes[key];
            }
        }
        setDayRecipes(newDayRecipes);
        handleClose();
    }

    const onSubmit = () => {
        let mealplan = {}
        for (let key in dayRecipes) {
            mealplan[key] = dayRecipes[key].map(recipe => {return {id: recipe.id, options: recipe.options }});
        }
        firebase.db.collection('users').doc(`${authContext.authUser.uid}`).update({ mealplan: mealplan })
            .then(() => {
                console.log('updated successfully');
                history.push(ROUTES.MEAL_PLAN);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const onUpdateOptions = (event, day, index) => {
        let name = event.target.name;
        console.log(name, day, index);
        if (name === 'servings') {
            //console.log(event.target.name, event.target.value, day, index);
            let tempDay = dayRecipes[day];
            let tempRecipe = tempDay[index];
            tempRecipe.options.servings = event.target.value;
            tempDay[index] = tempRecipe;
            setDayRecipes({...dayRecipes, [day]: tempDay})
        } else if (name === 'leftovers') {
            //console.log(event.target.name, event.target.checked, day, index);
            let tempDay = dayRecipes[day];
            let tempRecipe = tempDay[index];
            tempRecipe.options.leftovers = event.target.checked;
            tempDay[index] = tempRecipe;
            setDayRecipes({...dayRecipes, [day]: tempDay})
        }
        
    }

    return (
        <React.Fragment>
            <div className={classes.root}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Grid container spacing={2}>
                        <Grid item xs={6} md={3} className={classes.recipeList}>
                            <div className={classes.searchBox} >
                                <Autocomplete
                                    multiple
                                    freeSolo
                                    id='search'
                                    value={search}
                                    onChange={(event, newValue) => {
                                        setSearch(newValue.map(word => word.toLowerCase()));
                                    }}
                                    inputValue={searchText}
                                    onInputChange={(event, newInputValue) => {
                                        setSearchText(newInputValue);
                                    }}
                                    options={[]}
                                    renderInput={(params) => (
                                        <TextField {...params} variant='outlined' label='Search' />
                                    )}
                                />
                            </div>
                            {loading &&
                                <React.Fragment>
                                    <SkeletonCard />
                                    <SkeletonCard />
                                    <SkeletonCard />
                                    <SkeletonCard />
                                    <SkeletonCard />
                                    <SkeletonCard />
                                    <SkeletonCard />
                                </React.Fragment>}
                            {!loading &&
                                <div>
                                    <Droppable droppableId='recipeList'>
                                        {provided => (
                                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                                {recipes.filter(recipe => search.every(s => recipe.searchable.includes(s.toLowerCase()))).map(
                                                    (recipe, index) =>
                                                        <Draggable draggableId={"list_" + recipe.id} index={index} key={"list_" + recipe.id}>
                                                            {(draggableProvided, snapshot) => {
                                                                return (
                                                                    <>
                                                                        <div ref={draggableProvided.innerRef}
                                                                            {...draggableProvided.draggableProps}
                                                                            {...draggableProvided.dragHandleProps}
                                                                            style={{
                                                                                ...draggableProvided.draggableProps.style,
                                                                                transform: snapshot.isDragging ? draggableProvided.draggableProps.style?.transform : 'translate(0px, 0px)',
                                                                            }}
                                                                            className={classes.recipeCardDiv}>
                                                                            <MealPlannerRecipeCard
                                                                                {...recipe}
                                                                            />
                                                                        </div>
                                                                        {snapshot.isDragging &&
                                                                            <div style={{ transform: 'none !important', margin: '8px' }}>
                                                                                <MealPlannerRecipeCard
                                                                                    {...recipe}
                                                                                />
                                                                            </div>
                                                                        }
                                                                    </>
                                                                )
                                                            }}
                                                        </Draggable>
                                                )}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                    {moreData && <div ref={loader}>Loading More...</div>}
                                </div>
                            }
                        </Grid>
                        <Grid item xs={6} md={9} className={classes.daysContainerParent}>
                            <Grid container spacing={1} className={classes.daysContainer}>
                                {Object.entries(dayRecipes).map(([day, recipeArray], index) =>
                                    <Grid item xs={12} md={4} key={index} className={classes.dayColumn}>
                                        <Paper>
                                            <Typography className={classes.dayHeader} variant="h6">{convertDate(day)}</Typography>
                                            <Divider />
                                            <Droppable droppableId={day}>
                                                {provided => (
                                                    <div className={classes.dayColumnDiv} ref={provided.innerRef} {...provided.droppableProps}>
                                                        {dayRecipes[day].map(
                                                            (recipe, rindex) =>
                                                                <Draggable draggableId={day + "_" + recipe.id} index={rindex} key={day + "_" + recipe.id}>
                                                                    {provided => (
                                                                        <div ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            className={classes.recipeCardDiv}>
                                                                            <MealPlannerRecipeCard
                                                                                enableDropdown={true}
                                                                                onUpdateOptions={(event) => onUpdateOptions(event, day, rindex)}
                                                                                {...recipe}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                        )}
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </Paper>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </DragDropContext>
            </div>
            <AppBar position="fixed" color="primary" className={classes.appBar}>
                <Toolbar>
                    <div className={classes.grow} />
                    <Button
                        className={classes.bottomButtons}
                        color='inherit'
                        onClick={() => setOpen(true)}
                        endIcon={<TodayIcon />}>
                        Change Dates
                    </Button>
                    <Button
                        className={classes.iconButtons}
                        color='inherit'
                        onClick={onSubmit}
                        endIcon={<BackupIcon />}>
                        Submit
                    </Button>
                </Toolbar>
            </AppBar>
            <Dialog open={open} onClose={null}>
                <DialogTitle id="form-dialog-title">Select Date Range</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        What days do you want to meal plan for?
          			</DialogContentText>
                    <DateRangePicker setDateRange={setDateRange} dateRange={dateRange} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        Cancel
          		</Button>
                    <Button onClick={onSubmitDate} color="secondary">
                        Submit
					</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default MealPlanner;
