import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ShortRecipeCard from '../components/ShortRecipeCard';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FirebaseContext, AuthUserContext } from '../firebase';
import DateRangePicker from '../components/DateRangePicker';
import * as ROUTES from '../constants/routes';
import { eachDayOfInterval, format, parse } from 'date-fns';
import { fetchMealPlan } from '../util/fetchMealPlan';

const useStyles = makeStyles((theme) => ({
    root: {
        //marginLeft: theme.spacing(2),
        //marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2),
        //height: 'calc(100vh - 56px)'
    },
    tabs: {
        ...theme.mixins.toolbar,
        [theme.breakpoints.down('xs')]: {
            paddingTop: '4px'
        },
        [theme.breakpoints.up('xs')]: {
            paddingTop: '8px'
        },
    },
    tabPanel: {
        paddingTop: theme.spacing(1),
        minHeight: 'calc(100vh - 300px)',
        overflow: 'hidden',
    },
    recipeCardWrapper: {
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    buildFab: {
        position: 'fixed',
        bottom: theme.spacing(8),
        right: theme.spacing(2),
    },
    editFab: {
        position: 'fixed',
        bottom: theme.spacing(8),
        right: theme.spacing(10),
    },
    noMealPlan: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        textAlign: 'center',
    },
}));

function MealPlanMobile() {
    const classes = useStyles();
    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const firebase = useContext(FirebaseContext);
    const authContext = useContext(AuthUserContext);
    const history = useHistory();
    const [dayRecipes, setDayRecipes] = useState({});
    const [open, setOpen] = useState(false);
    const [dateRange, setDateRange] = useState({ from: null, to: null });
    const [selectedTab, setSelectedTab] = useState(0);

    useEffect(() => {
        let tabIndex = Object.keys(dayRecipes).indexOf(format(new Date(), 'MM/dd/yyyy'));
        if (tabIndex !== -1) {
            setSelectedTab(tabIndex);
        }
    }, [dayRecipes]);

    useEffect(() => {
        //load data here
        setLoading(true);
        document.title = "Meal Plan"
        let userRef = firebase.db.collection('users').doc(`${authContext.authUser.uid}`);
        userRef.get()
            .then(doc => {
                if (doc.exists) {
                    let data = doc.data();
                    if(data.mealplan) {
                        let mealplan = Object.fromEntries(Object.entries(data.mealplan).sort());
                        return mealplan;                  
                    } else {
                        throw new Error('no meal plan');
                    }
                } else {
                    //no meal plan available
                    throw new Error('no meal plan');
                }
            }).then(placeholderplan => {return fetchMealPlan(firebase.db, placeholderplan)})
            .then(mealplan => {setDayRecipes(mealplan); setLoading(false);})
            .catch(error => {
                console.log(error);
                setLoading(false);
            });
    }, [firebase.db, authContext.authUser.uid]);

    const handleClose = () => {
        setOpen(false);
    }

    const convertDate = (day) => {
        return format(parse(day, 'MM/dd/yyyy', new Date()), 'EEE M/d');
    }

    const onSubmit = () => {
        let dateArray = eachDayOfInterval({ start: dateRange.from, end: dateRange.to });
        dateArray = dateArray.map(dt => format(dt, 'MM/dd/yyyy')); //returns ex: 4/8/1998
        history.push({
            pathname: ROUTES.MEAL_PLANNER,
            state: { dateArray: dateArray },
        });
    }

    const onEdit = () => {
        history.push({
            pathname: ROUTES.MEAL_PLANNER,
            state: {
                dayRecipes: dayRecipes,
            },
        });
    }

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    }

    const handleTabIndexChange = (index) => {
        setSelectedTab(index);
    }

    return (
        <React.Fragment>
            {Object.keys(dayRecipes).length !== 0 &&
                <React.Fragment>
                    <div className={classes.root}>
                        <AppBar position='sticky' color="default">
                            <Tabs
                                className={classes.tabs}
                                value={selectedTab}
                                onChange={handleTabChange}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                scrollButtons="auto">
                                {Object.entries(dayRecipes).map(([day, dayRecipeArray], index) =>
                                    <Tab label={convertDate(day)} id={`tab_${index}`} key={`tab_${index}`} />
                                )}
                            </Tabs>
                        </AppBar>
                        <SwipeableViews
                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                            index={selectedTab}
                            onChangeIndex={handleTabIndexChange}>
                        {Object.entries(dayRecipes).map(([day, dayRecipeArray], index) =>
                            <div
                                className={classes.tabPanel}
                                role='tabpanel'
                                hidden={selectedTab !== index}
                                id={`tabpanel_div_${index}`}
                                key={`tabpanel_${index}`}
                            >
                                {selectedTab === index &&
                                    dayRecipeArray.map((recipe, index) =>
                                        <div className={classes.recipeCardWrapper} key={`${day}_${index}`}>
                                            <ShortRecipeCard {...recipe} />
                                        </div>
                                    )
                                }
                            </div>
                        )}
                        </SwipeableViews>
                    </div>
                    <Tooltip title="Edit Meal Plan">
                        <Fab className={classes.editFab}
                            color="primary"
                            aria-label="add"
                            onClick={onEdit}>
                            <EditIcon />
                        </Fab>
                    </Tooltip>
                </React.Fragment>
            }
            <Tooltip title="Create New Meal Plan">
                <Fab className={classes.buildFab}
                    color="secondary"
                    aria-label="add"
                    onClick={() => setOpen(true)}>
                    <AddIcon />
                </Fab>
            </Tooltip>
            <Dialog open={open} onClose={null}>
                <DialogTitle id="form-dialog-title">Select Date Range</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        What days do you want to meal plan for?
          			</DialogContentText>
                    <DateRangePicker setDateRange={setDateRange} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        Cancel
          		</Button>
                    <Button onClick={onSubmit} color="secondary">
                        Submit
					</Button>
                </DialogActions>
            </Dialog>
            {((Object.keys(dayRecipes).length === 0) && !loading) &&
                <div className={classes.root}>
                    <div className={classes.noMealPlan}>
                        <Typography variant='h5'>Create a Meal Plan!</Typography>
                    </div>
                </div>
            }
            {loading &&
                <div className={classes.root}>
                    <div className={classes.noMealPlan}>
                        <Typography variant='h5'>Loading...</Typography>
                    </div>
                </div>
            }
        </React.Fragment>
    );
}

export default MealPlanMobile;
