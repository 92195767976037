import React, { useEffect, useState, useContext, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import ShortRecipeCard from '../components/ShortRecipeCard';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FirebaseContext, AuthUserContext } from '../firebase';
import DateRangePicker from '../components/DateRangePicker';
import * as ROUTES from '../constants/routes';
import { eachDayOfInterval, format, parse } from 'date-fns';
import { fetchMealPlan } from '../util/fetchMealPlan';

const useStyles = makeStyles((theme) => ({
    root: {
        height: 'calc(100vh - 64px)',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(1),
        overflow: 'hidden',
    },
    daysContainer: {
        height: '100%',
        display: 'flex',
        flexWrap: 'nowrap',
        overflowX: 'auto',
    },
    dayColumn: {
        height: '100%',
        flexShrink: 0,
        flexGrow: 1,
        textAlign: 'center',
    },
    dayHeader: {
        padding: theme.spacing(1),
        whiteSpace: "pre-wrap",
        wordWrap: 'break-word',
    },
    headerDivider: {
        marginBottom: theme.spacing(2),
    },
    recipeCardWrapper: {
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    buildFab: {
		position: 'fixed',
		bottom: theme.spacing(2),
		right: theme.spacing(2),
	},
	editFab: {
		position: 'fixed',
		bottom: theme.spacing(2),
		right: theme.spacing(10),
	},
    noMealPlan: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        textAlign: 'center',
    },
}));

function MealPlan() {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const firebase = useContext(FirebaseContext);
    const authContext = useContext(AuthUserContext);
    const history = useHistory();
    const dayRef = useRef(null);
    const [dayRecipes, setDayRecipes] = useState({});
    const [open, setOpen] = useState(false);
    const [dateRange, setDateRange] = useState({ from: null, to: null });

    const executeScroll = () => {
        if (dayRef.current) {
            dayRef.current.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'start'});
            //window.scroll({top: 0});
        }
    }

    useEffect(() => {
        executeScroll();
    }, [dayRecipes]);

    useEffect(() => {
        //load data here
        setLoading(true);
        document.title = "Meal Plan"
        let userRef = firebase.db.collection('users').doc(`${authContext.authUser.uid}`);
        userRef.get()
            .then(doc => {
                if (doc.exists) {
                    let data = doc.data();
                    if(data.mealplan) {
                        let mealplan = Object.fromEntries(Object.entries(data.mealplan).sort());
                        return mealplan;                  
                    } else {
                        throw new Error('no meal plan');
                    }
                } else {
                    //no meal plan available
                    throw new Error('no meal plan');
                }
            }).then(placeholderplan => {return fetchMealPlan(firebase.db, placeholderplan)})
            .then(mealplan => {setDayRecipes(mealplan); setLoading(false);})
            .catch(error => {
                console.log(error);
                setLoading(false);
            });
    }, [firebase.db, authContext.authUser.uid]);

    const handleClose = () => {
        setOpen(false);
    }

    const convertDate = (day) => {
        return format(parse(day, 'MM/dd/yyyy', new Date()), 'EEE M/d');
    }

    const onSubmit = () => {
        let dateArray = eachDayOfInterval({ start: dateRange.from, end: dateRange.to });
        dateArray = dateArray.map(dt => format(dt, 'MM/dd/yyyy')); //returns ex: 4/8/1998
        history.push({
            pathname: ROUTES.MEAL_PLANNER,
            state: { dateArray: dateArray },
        });
    }

    const onEdit = () => {
        history.push({
            pathname: ROUTES.MEAL_PLANNER,
            state: {
                dayRecipes: dayRecipes,
            },
        });
    }

    return (
        <React.Fragment>
            {Object.keys(dayRecipes).length !== 0 &&
                <React.Fragment>
                    <div className={classes.root}>
                        <Grid container spacing={1} className={classes.daysContainer}>
                            {Object.entries(dayRecipes).map(([day, dayRecipeArray], index) =>
                                <Grid item xs={12} sm={6} md={3} xl={2} key={index} className={classes.dayColumn}>
                                    <Paper ref={day === format(new Date(), 'MM/dd/yyyy') ? dayRef : null}>
                                        <Typography className={classes.dayHeader} variant="h6">{convertDate(day)}</Typography>
                                        <Divider className={classes.headerDivider} />
                                        {dayRecipeArray.map((recipe, index) =>
                                            <div className={classes.recipeCardWrapper} key={`${day}_${index}`}>
                                                <ShortRecipeCard {...recipe} />
                                            </div>
                                        )}
                                    </Paper>
                                </Grid>
                            )}
                        </Grid>
                    </div>
                    <Tooltip title="Edit Meal Plan">
                        <Fab className={classes.editFab}
                            color="primary"
                            aria-label="add"
                            onClick={onEdit}>
                            <EditIcon />
                        </Fab>
                    </Tooltip>
                </React.Fragment>
            }
            <Tooltip title="Create New Meal Plan">
                <Fab className={classes.buildFab}
                    color="secondary"
                    aria-label="add"
                    onClick={() => setOpen(true)}>
                    <AddIcon />
                </Fab>
            </Tooltip>
            <Dialog open={open} onClose={null}>
                <DialogTitle id="form-dialog-title">Select Date Range</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        What days do you want to meal plan for?
          			</DialogContentText>
                    <DateRangePicker setDateRange={setDateRange} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        Cancel
          		</Button>
                    <Button onClick={onSubmit} color="secondary">
                        Submit
					</Button>
                </DialogActions>
            </Dialog>
            {((Object.keys(dayRecipes).length === 0) && !loading) &&
                <div className={classes.root}>
                    <div className={classes.noMealPlan}>
                        <Typography variant='h5'>Create a Meal Plan!</Typography>
                    </div>
                </div>
            }
            {loading &&
                <div className={classes.root}>
                    <div className={classes.noMealPlan}>
                        <Typography variant='h5'>Loading...</Typography>
                    </div>
                </div>
            }
        </React.Fragment>
    );
}

export default MealPlan;
