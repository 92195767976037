import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthUserContext } from '../firebase';
import * as ROUTES from '../constants/routes';
import Loading from '../components/Loading';

function PublicRoute({ children, ...props }) {
    const authContext = useContext(AuthUserContext);
    return (
        <Route
            {...props}
            render={({ location }) => {
                if (authContext.authUser === 'loading') {
                    return <Loading />
                } else if ((authContext.authUser !== 'loading') && (authContext.authUser !== null)) {
                    return <Redirect
                        to={{
                            pathname: ROUTES.RECIPE_LIST,
                            state: { from: location }
                        }}
                    />
                } else {
                    return children
                }
            }
            }
        />
    )
};

export default PublicRoute;