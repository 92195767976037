import React, { useState, useContext } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import * as ROUTES from '../constants/routes';
import { FirebaseContext, AuthUserContext } from '../firebase';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    googleButton: {
        margin: theme.spacing(1, 0, 2)
    }
}));

export default function SignIn() {
    const classes = useStyles();
    let history = useHistory();
    let location = useLocation();
    let { from } = location.state || { from: { pathname: ROUTES.RECIPE_LIST } }
    const [values, setValues] = useState({ email: "", password: "" });
    const [error, setError] = useState(false);
    const firebase = useContext(FirebaseContext);
    const authContext = useContext(AuthUserContext);

    const handleInputChange = event => {
        const { name, value } = event.target
        setValues({ ...values, [name]: value });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        firebase.signInWithEmailAndPassword(values.email, values.password)
            .then(() => {
                authContext.setAuthUser('loading');
                //setValues({ email: "", password: "" });
                history.replace(from);
            })
            .catch(error => {
                console.log(error);
                setError(true);
            });
    }

    const handleSignInWithGoogle = (event) => {
        firebase.signInWithGoogle()
            .then(() => {
                authContext.setAuthUser('loading');
                //setValues({ email: "", password: "" });
                history.replace(from);
            })
            .catch(error => {
                console.log(error);
            });
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <form className={classes.form} onSubmit={handleSubmit} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={values.email}
                        onChange={handleInputChange}
                        error={error}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={values.password}
                        onChange={handleInputChange}
                        error={error}
                        helperText={error ? 'Invalid email/password': ""}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Sign In
                    </Button>
                    <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        className={classes.googleButton}
                        onClick={handleSignInWithGoogle}
                    >
                        Sign In with Google
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link component={RouterLink} to={ROUTES.PASSWORD_RESET} variant="body2" color='secondary'>
                                Forgot password?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link component={RouterLink} to={ROUTES.SIGN_UP} variant="body2" color='secondary'>
                                Don't have an account? Sign Up
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Container>
    );
}