import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import Chip from '@material-ui/core/Chip';
import DefaultImage from '../assets/default.svg';

const useStyles = makeStyles((theme) => ({
    root: {
        //marginTop: theme.spacing(2),
    },
    leftoversroot: {
        filter: 'contrast(0.5)',
    },
    card: {
        maxWidth: 350,
        margin: 'auto',
    },
    media: {
        height: 0,
        //paddingTop: '56.25%',
        paddingTop: '45%'
    },
    avatar: {
        backgroundColor: red[500],
    },
    chip: {
        marginLeft: theme.spacing(0.5),
        marginTop: theme.spacing(0.5),
    },
    title: {
        textAlign: 'center',
        marginBottom: theme.spacing(0.5),
    },
    chipContainer: {
        textAlign: 'center',
    },
    moreIcon: {
        marginLeft: 'auto',
    }
}));

function ShortRecipeCard(props) {
    const classes = useStyles();
    const pathName = "/view/" + props.id;

    return (
        <div className={props.options.leftovers ? classes.leftoversroot : classes.root}>
            <Card className={classes.card}>
                <CardActionArea component={Link}
                    to={{
                        pathname: pathName,
                        state: props,
                    }}>
                    {props.picture !== false &&
                        <CardMedia
                            className={classes.media}
                            image={props.picture ? props.pictureUrl : DefaultImage}
                            title={props.title}
                        />
                    }
                    <CardContent>
                        <Typography className={classes.title} variant="body1" color="textPrimary">
                            {props.title}
                        </Typography>
                        <div className={classes.chipContainer}>
                            {props.time !== "" && <Chip className={classes.chip} size='small' label={props.time} />}
                            {props.cuisine.map((cuisine, index) => <Chip className={classes.chip} size='small' key={"cuisine" + index} label={cuisine} />)}
                            {props.category.map((category, index) => <Chip className={classes.chip} size='small' key={"category" + index} label={category} />)}
                        </div>
                    </CardContent>
                </CardActionArea>

            </Card>
        </div>
    );
}

export default ShortRecipeCard;
