export const BASE = 'https://recipes.parikhm.com';
export const PARSER = 'https://parser.parikhm.com'
export const SCRAPER = 'https://recipes.parikhm.com/api/scraper';
//export const SCRAPER = 'http://localhost:5000/scrape';
export const SIGN_UP = '/sign_up';
export const SIGN_IN = '/sign_in';
export const PASSWORD_RESET = '/password_reset';
export const PUBLIC_RECIPE_LIST = '/public_recipe_list';
export const RECIPE_LIST = '/recipe_list';
export const RECIPE_VIEWER = '/view/:id';
export const RECIPE_BUILDER = '/recipe_builder';
export const MEAL_PLANNER = '/meal_planner';
export const MEAL_PLAN = '/meal_plan';
export const GROCERY_LIST = '/grocery_list';
export const HOME = '/';