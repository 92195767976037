import { all, create } from 'mathjs';

const mathConfig = {
    number: 'number',
};
const math = create(all, mathConfig);
math.createUnit({
    none: {
        //placeholder   
    },
    tbsp: {
        definition: '1 tablespoon',
    },
    tsp: {
        definition: '1 teaspoon',
    },
    cloves: {},
    sprigs: {}
},
    {
        override: true,
    });

//DONE ignore recipe if labeled leftovers
//DONE multiply by servings
//DONE if labels are empty add header that says unlabelled recipe, add raw ingredients to end
//DONE return decimals instead of fractions, round to 2 decimal places
//add units across type (volume or mass)
//DONE if no unit, use "+ more (name)" format
//attribute recipe to each ingredient
//for unit/names should be able to match regardless of plurality

export const generateGroceryList = async (mealplan) => {
    let [ingredientMap, appendArray] = mapper(mealplan);
    ingredientMap = mapToArr(ingredientMap);
    return ingredientMap.concat(appendArray);
}

//creates dictionary of all ingredient names with corresponding quantities and recipes used in
//ignores "leftovers" and multiplies by serving size given
const mapper = (mealplan) => {
    let ingredientMap = {};
    let appendArray = [];
    for (let key in mealplan) {
        let day = mealplan[key];
        day.forEach(recipe => {
            let hasLabels = false;
            if (recipe.options.leftovers === false) {
                let labels = recipe.labels;
                labels.forEach(label => {
                    let name = label.name.join(" ").toLowerCase();
                    let unit = label.unit[0] ? label.unit[0].toLowerCase() : "none";
                    let servings = math.fraction(recipe.options.servings);
                    let qty = label.qty[0] ? math.multiply(math.fraction(servings), math.fraction(label.qty[0])) : "";
                    qty = math.number(qty);
                    if (label.name.length || label.unit.length || label.qty.length) {
                        hasLabels = true;
                    }
                    let mathunit;
                    try {
                        mathunit = math.unit(qty, unit);
                    } catch (e) {
                        if(!math.Unit.isValuelessUnit(unit)) {
                            math.createUnit(unit);
                            mathunit = math.unit(qty, unit);
                        }
                    }
                    let standardname = ingMatcher(name, Object.keys(ingredientMap));
                    if (standardname) {
                        //ingredientMap[name].amounts = [...ingredientMap[name].amounts, mathunit];
                        ingredientMap[standardname].amounts = combineLikeUnits(ingredientMap[standardname].amounts, mathunit);
                        ingredientMap[standardname].recipes = [...ingredientMap[standardname].recipes, { title: recipe.title, id: recipe.id }];
                    } else {
                        ingredientMap[name] = { amounts: [mathunit], recipes: [{ title: recipe.title, id: recipe.id }] };
                    }

                });
                if (!hasLabels) {
                    let header = { item: `~For ${recipe.title} (was not tagged)~`, recipes: [] };
                    let end = { item: "~END~", recipes: [] };
                    let ingredients = recipe.ingredients.map(ingredient => { return { item: ingredient, recipes: [{ title: recipe.title, id: recipe.id }] } });
                    appendArray.push(header, ...ingredients, end);
                }
            }

        });
    }
    delete ingredientMap[""];
    return [ingredientMap, appendArray];
}

//matches ingredients with plural forms
const ingMatcher = (newName, ingredientsList) => {
    let newNameList = [
        newName,
        newName + 's',
        newName + 'es'
    ];
    for (const ingredientName of ingredientsList) {
        let ingredientNameList = [
            ingredientName,
            ingredientName + 's',
            ingredientName + 'es'
        ];
        if (ingredientNameList.includes(newName)) {
            return ingredientName;
        } else if (newNameList.includes(ingredientName)) {
            return ingredientName;
        }
    }
    return false;
}

//combines the same unit types
const combineLikeUnits = (amounts, mathunit) => {
    let addedFlag = false;
    amounts.forEach((amount, index, arr) => {
        if (unitMatcher(amount.units[0].unit.name, mathunit.units[0].unit.name)) {
            arr[index] = math.add(amount, mathunit);
            addedFlag = true;
        }
    });
    if (!addedFlag) {
        amounts.push(mathunit);
    }

    return amounts;
}

//matches units with plural forms
const unitMatcher = (first, second) => {
    let reg1 = new RegExp(`(${first}s)|(${first}es)|(${first})`, 'gm');
    let reg2 = new RegExp(`(${second}s)|(${second}es)|(${second})`, 'gm');
    let match1 = second.match(reg1);
    let match2 = first.match(reg2);
    if (match1) {
        return true;
    } else if (match2) {
        return true;
    } else {
        return false;
    }
}

//convert ingredient map to human readable grocery list line items
const mapToArr = (ingMap) => {
    let stringArr = [];
    for (let key in ingMap) {
        let ingredient = ingMap[key];
        let linestring = [];
        ingredient.amounts.forEach(line => {
            if (line.units[0].unit.name === 'none' && math.equal(line.value, 0)) {
                //add "some name"
                linestring.push(`some ${key}`);
            } else if (line.units[0].unit.name === 'none') {
                //add "qty name"
                linestring.push(`${math.format(line.value, 3)} ${key}`)
            } else if (math.equal(line.value, 0)) {
                //add "some name"
                linestring.push(key);
            } else {
                //add "qty unit name"
                linestring.push(`${math.format(line, 3)} ${key}`)
            }
        });
        linestring = linestring.join(" + ");
        stringArr.push({ item: linestring, recipes: ingredient.recipes });
    }
    return stringArr;
}