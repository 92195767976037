import React, { useEffect, useContext, useState } from 'react';
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import EditIcon from '@material-ui/icons/Edit';
import ShareIcon from '@material-ui/icons/Share';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Chip from '@material-ui/core/Chip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import * as ROUTES from '../constants/routes';
import { FirebaseContext } from '../firebase';
import DefaultImage from '../assets/default.svg';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(2),
    },
    card: {
        maxWidth: 350,
        margin: 'auto',
    },
    media: {
        height: 0,
        paddingTop: '56.25%',
    },
    cardContent: {
        padding: theme.spacing(1),
    },
    cardActions: {
        paddingTop: 0,
    },
    avatar: {
        backgroundColor: red[500],
    },
    chip: {
        marginLeft: theme.spacing(0.5),
        marginTop: theme.spacing(0.5),
    },
    title: {
        textAlign: 'center',
    },
    chipContainer: {
        textAlign: 'center'
    },
    moreIcon: {
        marginLeft: 'auto',
    }
}));

function RecipeCard(props) {
    const classes = useStyles();
    const pathName = "/view/" + props.id;
    const firebase = useContext(FirebaseContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const [recipe, setRecipe] = useState(props);

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const handleDelete = () => {
        if(window.confirm('Are you sure you want to delete this recipe?')) {
            firebase.db.collection('recipes').doc(props.id).delete()
                .then(() => {
                    //check if image exists props.picture
                    if(props.picture && props.pictureUrl.includes('firebase')) {
                        firebase.storage.ref().child('recipe_images/' + props.id).delete()
                        .then(() => {
                            props.delete(props.id);
                        })
                        .catch(error => {
                            console.log(error);
                        });
                    } else {
                        props.delete(props.id);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        }
        handleClose();
    }

    useEffect(() => {
        let tempRecipe = {...props};
        delete tempRecipe.delete;
        setRecipe(tempRecipe);
    }, [props])

    return (
        <div className={classes.root}>
            <Card className={classes.card}>
                <CardActionArea component={Link}
                    to={{
                        pathname: pathName,
                        state: recipe,
                    }}>
                    {props.picture !== false &&
                        <CardMedia
                            className={classes.media}
                            image={props.picture ? props.pictureUrl : DefaultImage}
                            title={props.title}
                        />
                    }
                    <CardContent className={classes.cardContent}>
                        <Typography className={classes.title} variant="h6" color="textPrimary">
                            {props.title}
                        </Typography>
                        
                        <div className={classes.chipContainer}>
                        {props.time !== "" && <Chip className={classes.chip} label={props.time} />}
                        {props.cuisine.map((cuisine, index) => <Chip className={classes.chip} key={"cuisine" + index} label={cuisine} />)}
                        {props.category.map((category, index) => <Chip className={classes.chip} key={"category" + index} label={category} />)}
                        </div>
                    </CardContent>
                </CardActionArea>
                <CardActions className={classes.cardActions} disableSpacing>
                    <IconButton aria-label="edit" component={Link}
                        to={{
                            pathname: ROUTES.RECIPE_BUILDER,
                            state: recipe,
                        }}
                    >
                        <EditIcon />
                    </IconButton>
                    <CopyToClipboard text={ROUTES.BASE + pathName}>
                        <IconButton aria-label="share">
                            <ShareIcon />
                        </IconButton>
                    </CopyToClipboard>
                    <IconButton 
                        className={classes.moreIcon}
                        onClick={handleOpen}
                        >
                        <MoreVertIcon />
                    </IconButton>
                </CardActions>
            </Card>
            <Menu
                id='more-options'
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleDelete}>Delete</MenuItem>
            </Menu>
        </div>
    );
}

export default RecipeCard;
