import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import MealPlannerRecipeCard from '../components/MealPlannerRecipeCard';
import Skeleton from '@material-ui/lab/Skeleton';
import TextField from '@material-ui/core/TextField';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import BackupIcon from '@material-ui/icons/Backup';
import TodayIcon from '@material-ui/icons/Today';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FirebaseContext, AuthUserContext } from '../firebase';
import { eachDayOfInterval, parse, format } from 'date-fns';
import DateRangePicker from '../components/DateRangePicker';
import * as ROUTES from '../constants/routes';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(2),
    },
    tabs: {
        ...theme.mixins.toolbar,
        [theme.breakpoints.down('xs')]: {
            paddingTop: '4px'
        },
        [theme.breakpoints.up('xs')]: {
            paddingTop: '8px'
        },
    },
    tabPanel: {
        paddingTop: theme.spacing(1),
        height: '100%',
        minHeight: 'calc(100vh - 300px)',
        overflow: 'hidden',
    },
    offset: theme.mixins.toolbar,
    skeletonContainer: {
        marginTop: theme.spacing(2),
    },
    skeleton: {
        margin: theme.spacing(1),
    },
    searchBox: {
        marginBottom: 0,
    },
    search: {
        width: '100%',
    },
    recipeList: {
        height: '100%',
        overflowY: 'scroll',
        padding: theme.spacing(2),
    },
    recipeCardDiv: {
        margin: theme.spacing(1),
    },
    selectedRecipeCardDiv: {
        margin: theme.spacing(1),
        borderStyle: 'solid',
        borderColor: theme.palette.secondary.main
    },
    appBar: {
        top: 'auto',
        bottom: 0,
    },
    grow: {
        flex: 1,
    },
    bottomButtons: {
        marginLeft: theme.spacing(2),
    },
    addFab: {
        position: 'fixed',
        bottom: theme.spacing(8),
        right: theme.spacing(2),
    },
}));

function MealPlannerMobile() {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [recipes, setRecipes] = useState([]);
    const [search, setSearch] = useState([]);
    const [searchText, setSearchText] = useState("");
    const firebase = useContext(FirebaseContext);
    const authContext = useContext(AuthUserContext);
    const location = useLocation();
    const history = useHistory();
    const [lastVisible, setLastVisible] = useState(null);
    const [moreData, setMoreData] = useState(true);
    const [dayRecipes, setDayRecipes] = useState({});
    const [open, setOpen] = useState(false);
    const [dateRange, setDateRange] = useState({ from: null, to: null });
    const [selectedTab, setSelectedTab] = useState(0);
    const [recipesOpen, setRecipesOpen] = useState(false);
    const [selectedRecipeIDs, setSelectedRecipeIDs] = useState([]);

    let pageLimit = 25;

    useEffect(() => {
        let dateArray = location.state.dateArray;
        let passedDayRecipes = location.state.dayRecipes;
        if (dateArray) {
            let tempDayRecipes = Object.fromEntries(dateArray.map(dt => [dt, []]));
            setDayRecipes(tempDayRecipes);
            let tempDateRange = { from: dateToObj(dateArray[0]), to: dateToObj(dateArray[dateArray.length - 1]), enteredTo: dateToObj(dateArray[dateArray.length - 1]) }
            setDateRange(tempDateRange);
        } else if (passedDayRecipes) {
            setDayRecipes(passedDayRecipes);
            let dayKeys = Object.keys(passedDayRecipes);
            let tempDateRange = { from: dateToObj(dayKeys[0]), to: dateToObj(dayKeys[dayKeys.length - 1]), enteredTo: dateToObj(dayKeys[dayKeys.length - 1]) }
            setDateRange(tempDateRange);
        }

    }, [location.state])

    useEffect(() => {
        setLoading(true);
        setMoreData(true);
        document.title = "Meal Planner"
        let recipesRef = firebase.db.collection('recipes')
            .where('authorUID', '==', authContext.authUser.uid)
            .orderBy('title')
            .limit(pageLimit);
        recipesRef.get()
            .then(querySnapshots => {
                let last = querySnapshots.docs[querySnapshots.docs.length - 1];
                setLastVisible(last);
                if (typeof last === "undefined") {
                    setMoreData(false);
                }
                let recipeList = [];
                querySnapshots.forEach(doc => {
                    let recipe = doc.data();
                    recipe.id = doc.id;
                    recipe.options = { servings: 1, leftovers: false };
                    recipeList.push(recipe);
                });
                setRecipes(recipeList);
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
            });
    }, [firebase.db, authContext.authUser.uid, pageLimit]);

    const fetchMoreData = useCallback(() => {
        if (moreData && (lastVisible !== null || typeof lastVisible !== "undefined")) {
            let recipesRef = firebase.db.collection('recipes')
                .where('authorUID', '==', authContext.authUser.uid)
                .orderBy('title')
                .startAfter(lastVisible)
                .limit(pageLimit);
            recipesRef.get()
                .then(querySnapshots => {
                    let last = querySnapshots.docs[querySnapshots.docs.length - 1];
                    setLastVisible(last);
                    if (typeof last === "undefined") {
                        setMoreData(false);
                    }
                    let recipeList = recipes;
                    querySnapshots.forEach(doc => {
                        let recipe = doc.data();
                        recipe.id = doc.id;
                        recipe.options = { servings: 1, leftovers: false };
                        recipeList.push(recipe);
                    });

                    setRecipes(recipeList);
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }, [moreData, lastVisible, recipes, authContext.authUser.uid, firebase.db, pageLimit])

    const loader = useCallback(node => {
        if (node !== null) {
            new IntersectionObserver(
                entries => {
                    entries.forEach(entry => {
                        //console.log(entry)
                        if (entry.isIntersecting) {
                            fetchMoreData();
                        }
                    });
                },
                { threshold: 0.9, root: null }
            ).observe(node);
        }
    }, [fetchMoreData]);

    const SkeletonCard = () => (
        <div className={classes.skeletonContainer}>
            <Skeleton className={classes.skeleton} variant='rect' height={140} />
            <Skeleton className={classes.skeleton} variant='rect' />
            <Skeleton className={classes.skeleton} variant='rect' width='60%' />
        </div>
    );

    function onDragEnd(result) {
        const { source: droppableSource, destination: droppableDestination } = result;

        // dropped outside the list
        if (!droppableDestination) {
            //console.log('dropped outside of any destination');
            return;
        }

        const sourceID = droppableSource.droppableId;
        const destID = droppableDestination.droppableId;

        if ((sourceID === destID) && sourceID === 'recipeList') {
            //do nothing
            //console.log('moved from recipeList to recipeList')
        } else if ((sourceID === destID) && sourceID !== 'recipeList') {
            //console.log('moved from day to same day');
            //Reorder within same day
            const dayRecipeList = Array.from(dayRecipes[sourceID]);
            const [removed] = dayRecipeList.splice(droppableSource.index, 1);
            dayRecipeList.splice(droppableDestination.index, 0, removed);
            setDayRecipes({ ...dayRecipes, [destID]: dayRecipeList })
        } else if ((sourceID !== destID) && sourceID === 'recipeList') {
            //move from recipeList to a day
            //console.log('moved from recipeList to day');
            const sourceClone = Array.from(recipes);
            const destClone = Array.from(dayRecipes[destID]);
            const [removed] = _.cloneDeep(sourceClone.splice(droppableSource.index, 1));
            if (!Object.values(destClone).map(value => value.id).includes(removed.id)) {
                destClone.splice(droppableDestination.index, 0, removed);
                setDayRecipes({ ...dayRecipes, [destID]: destClone });
            }
        } else if ((sourceID !== destID) && destID === 'recipeList') {
            //move from a day to recipeList
            //console.log('moved from day to recipeList');
            const sourceClone = Array.from(dayRecipes[sourceID]);
            sourceClone.splice(droppableSource.index, 1);
            setDayRecipes({ ...dayRecipes, [sourceID]: sourceClone })
        } else if ((sourceID !== destID)) {
            //move between days
            //console.log('moved from day to different day');
            const sourceClone = Array.from(dayRecipes[sourceID]);
            const destClone = Array.from(dayRecipes[destID]);
            const [removed] = sourceClone.splice(droppableSource.index, 1);
            if (!Object.values(destClone).map(value => value.id).includes(removed.id)) {
                destClone.splice(droppableDestination.index, 0, removed)
                setDayRecipes({ ...dayRecipes, [sourceID]: sourceClone, [destID]: destClone })
            }
        }

    }

    const convertDate = (day) => {
        return format(parse(day, 'MM/dd/yyyy', new Date()), 'EEE M/d');
    }

    const dateToObj = (day) => {
        return parse(day, 'MM/dd/yyyy', new Date())
    }

    const handleClose = () => {
        setOpen(false);
    }

    const onSubmitDate = () => {
        let dateArray = eachDayOfInterval({ start: dateRange.from, end: dateRange.to });
        dateArray = dateArray.map(dt => format(dt, 'MM/dd/yyyy')); //returns ex: 4/8/1998
        let newDayRecipes = Object.fromEntries(dateArray.map(dt => [dt, []]));
        for (let key of dateArray) {
            if (dayRecipes[key]) {
                newDayRecipes[key] = dayRecipes[key];
            }
        }
        setDayRecipes(newDayRecipes);
        handleClose();
    }

    const onSubmit = () => {
        let mealplan = {}
        for (let key in dayRecipes) {
            mealplan[key] = dayRecipes[key].map(recipe => { return { id: recipe.id, options: recipe.options } });
        }
        firebase.db.collection('users').doc(`${authContext.authUser.uid}`).update({ mealplan: mealplan })
            .then(() => {
                console.log('updated successfully');
                history.push(ROUTES.MEAL_PLAN);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    }

    const handleSelectRecipe = (event, recipe) => {
        let tempSelectedRecipes = [...selectedRecipeIDs];
        if (tempSelectedRecipes.includes(recipe.id)) {
            const index = tempSelectedRecipes.indexOf(recipe.id);
            tempSelectedRecipes.splice(index, 1);
        } else {
            tempSelectedRecipes.push(recipe.id);
        }
        setSelectedRecipeIDs(tempSelectedRecipes);
    }

    const handleAdd = () => {
        let key = Object.keys(dayRecipes)[[selectedTab]];
        const dayRecipeIDs = dayRecipes[key].map(recipe => recipe.id);
        const tempSelectedRecipeIDs = [...new Set([...dayRecipeIDs, ...selectedRecipeIDs])];
        const newRecipeList = _.cloneDeep(recipes.filter(recipe => tempSelectedRecipeIDs.includes(recipe.id)));
        setDayRecipes({ ...dayRecipes, [key]: newRecipeList });
        setRecipesOpen(false);
        setSelectedRecipeIDs([]);
    }

    const onUpdateOptions = (event, day, index) => {
        let name = event.target.name;
        if (name === 'servings') {
            //console.log(event.target.name, event.target.value, day, index);
            let tempDay = dayRecipes[day];
            let tempRecipe = tempDay[index];
            tempRecipe.options.servings = event.target.value;
            tempDay[index] = tempRecipe;
            setDayRecipes({ ...dayRecipes, [day]: tempDay })
        } else if (name === 'leftovers') {
            //console.log(event.target.name, event.target.checked, day, index);
            let tempDay = dayRecipes[day];
            let tempRecipe = tempDay[index];
            tempRecipe.options.leftovers = event.target.checked;
            tempDay[index] = tempRecipe;
            setDayRecipes({ ...dayRecipes, [day]: tempDay })
        }

    }

    const onDelete = (event, day, index) => {
        let tempDay = dayRecipes[day];
        tempDay.splice(index, 1);
        setDayRecipes({...dayRecipes, [day]: tempDay})
    }

    return (
        <React.Fragment>
            <DragDropContext
                onDragEnd={onDragEnd}
            >
                <div className={classes.root}>
                    <AppBar position='sticky' color="default">
                        <Tabs
                            className={classes.tabs}
                            value={selectedTab}
                            onChange={handleTabChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="auto">
                            {Object.entries(dayRecipes).map(([day, dayRecipeArray], index) =>
                                <Tab label={convertDate(day)} id={`tab_${index}`} key={`tab_${index}`} />
                            )}
                        </Tabs>
                    </AppBar>
                    {Object.entries(dayRecipes).map(([day, dayRecipeArray], index) =>
                        <div
                            className={classes.tabPanel}
                            role='tabpanel'
                            hidden={selectedTab !== index}
                            id={`tabpanel_div_${index}`}
                            key={`tabpanel_${index}`}
                        >
                            {selectedTab === index &&
                                <div>
                                    <Droppable
                                        droppableId={day}
                                    >
                                        {provided => (
                                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                                {dayRecipeArray.map(
                                                    (recipe, rindex) =>
                                                        <Draggable draggableId={day + "_" + recipe.id} index={rindex} key={day + "_" + recipe.id}>
                                                            {provided => (
                                                                <div ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    className={classes.recipeCardDiv}>
                                                                    <MealPlannerRecipeCard
                                                                        enableDropdown={true}
                                                                        onUpdateOptions={(event) => onUpdateOptions(event, day, rindex)}
                                                                        onDelete={(event) => onDelete(event, day, rindex)}
                                                                        {...recipe}
                                                                    />
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                )}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </div>}
                        </div>
                    )}
                </div>
                <Tooltip title="Add Recipes">
                    <Fab className={classes.addFab}
                        color="secondary"
                        aria-label="add"
                        onClick={() => setRecipesOpen(true)}>
                        <AddIcon />
                    </Fab>
                </Tooltip>
                <div className={classes.offset} />
                <AppBar position="fixed" color="primary" className={classes.appBar}>
                    <Toolbar>
                        <div className={classes.grow} />
                        <Button
                            className={classes.bottomButtons}
                            color='inherit'
                            onClick={() => setOpen(true)}
                            endIcon={<TodayIcon />}>
                            Change Dates
                    </Button>
                        <Button
                            className={classes.iconButtons}
                            color='inherit'
                            onClick={onSubmit}
                            endIcon={<BackupIcon />}>
                            Submit
                    </Button>
                    </Toolbar>
                </AppBar>
                <Dialog open={open} onClose={null}>
                    <DialogTitle id="form-dialog-title">Select Date Range</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            What days do you want to meal plan for?
          			</DialogContentText>
                        <DateRangePicker setDateRange={setDateRange} dateRange={dateRange} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="secondary">
                            Cancel
          		</Button>
                        <Button onClick={onSubmitDate} color="secondary">
                            Submit
					</Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={recipesOpen} onClose={null} fullScreen>
                    <DialogTitle>Pick Recipes to Add</DialogTitle>
                    <div className={classes.recipeList}>
                        <div className={classes.searchBox}>
                            <Autocomplete
                                multiple
                                freeSolo
                                id='search'
                                value={search}
                                onChange={(event, newValue) => {
                                    setSearch(newValue.map(word => word.toLowerCase()));
                                }}
                                inputValue={searchText}
                                onInputChange={(event, newInputValue) => {
                                    setSearchText(newInputValue);
                                }}
                                options={[]}
                                renderInput={(params) => (
                                    <TextField {...params} variant='outlined' label='Search' />
                                )}
                            />
                        </div>
                        {loading &&
                            <React.Fragment>
                                <SkeletonCard />
                                <SkeletonCard />
                                <SkeletonCard />
                                <SkeletonCard />
                                <SkeletonCard />
                                <SkeletonCard />
                                <SkeletonCard />
                            </React.Fragment>}
                        {!loading &&
                            <div>
                                {recipes.filter(recipe => search.every(s => recipe.searchable.includes(s.toLowerCase()))).map(
                                    (recipe, index) =>
                                        <div
                                            key={recipe.id}
                                            className={selectedRecipeIDs.includes(recipe.id) ? classes.selectedRecipeCardDiv : classes.recipeCardDiv}
                                            onClick={(event) => handleSelectRecipe(event, recipe)}
                                        >
                                            <MealPlannerRecipeCard
                                                {...recipe}
                                            />
                                        </div>
                                )}
                                {moreData && <div ref={loader}>Loading More...</div>}
                            </div>
                        }
                    </div>
                    <DialogActions>
                        <Button color='secondary'
                            onClick={handleAdd}
                        >
                            Add
                        </Button>
                        <Button color='secondary'
                            onClick={() => { setRecipesOpen(false); setSelectedRecipeIDs([]) }}
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </DragDropContext>
        </React.Fragment>
    );
}

export default MealPlannerMobile;
