import React, { useState, useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { FirebaseContext } from '../firebase';
import * as ROUTES from '../constants/routes';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    successLabel: {
        textAlign: 'center',
    }
}));

export default function PasswordReset() {
    const classes = useStyles();
    const [values, setValues] = useState({ email: "" });
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(true);
    const firebase = useContext(FirebaseContext);

    const handleInputChange = event => {
        const { name, value } = event.target
        setValues({ ...values, [name]: value });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        firebase.resetPassword(values.email)
            .then(() => {
                setSuccess(true);
            })
            .catch(error => {
                console.log(error);
                setError(true);
            });
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Reset Password
                </Typography>
                <form className={classes.form} onSubmit={handleSubmit} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={values.email}
                        onChange={handleInputChange}
                        error={error}
                        helperText={error ? "Invalid email" : ""}
                        disabled={success}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={handleSubmit}
                        disabled={success}
                    >
                        Submit
                    </Button>
                    {success &&
                        <div className={classes.successLabel}>
                            <Typography variant="body1">
                                Password reset email sent!
                            </Typography>
                            <Link component={RouterLink} to={ROUTES.SIGN_IN} variant="body1" color='secondary'>
                                Back to sign in
                            </Link>
                        </ div>
                    }
                </form>
            </div>
        </Container>
    );
}