import React, { useState, useContext } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { FirebaseContext, AuthUserContext } from '../firebase';
import * as ROUTES from '../constants/routes';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(1, 0, 2),
    },
    googleButton: {
        margin: theme.spacing(3, 0, 2),
    }
}));

export default function SignUp() {
    const classes = useStyles();
    const [values, setValues] = useState({ firstName: "", lastName: "", email: "", password: "", confirmPassword: "" });
    const [passwordMatchError, setPasswordMatchError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const history = useHistory();
    const firebase = useContext(FirebaseContext);
    const authContext = useContext(AuthUserContext);

    const handleInputChange = event => {
        const { name, value } = event.target
        setValues({ ...values, [name]: value });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setPasswordMatchError(false);
        setPasswordError(false);
        setEmailError(false);
        if (values.password !== values.confirmPassword) {
            setPasswordMatchError(true);
        } else {
            setPasswordMatchError(false);
            firebase.createUserWithEmailAndPassword(values.email, values.password)
                .then((userCredential) => {
                    //setValues({ firstName: "", lastName: "", email: "", password: "", confirmPassword: "" });
                    userCredential.user.updateProfile({ displayName: values.firstName + " " + values.lastName })
                        .then(() => {
                            authContext.setAuthUser('loading');
                            history.push(ROUTES.RECIPE_LIST);
                        })
                        .catch(error => {
                            console.log(error);
                            //no idea what this catches
                        });
                })
                .catch(error => {
                    console.log(error);
                    switch (error.code) {
                        case 'auth/invalid-email':
                            setEmailError('Invalid Email');
                            break;
                        case 'auth/email-already-in-use':
                            setEmailError(error.message);
                            break;
                        case 'auth/weak-password':
                            setPasswordError(error.message);
                            break;
                        default:
                            setEmailError("Error");
                            setPasswordError("Error");
                    }
                })
        }
    }

    const handleSignInWithGoogle = (event) => {
        firebase.signInWithGoogle()
            .then(() => {
                //setValues({ firstName: "", lastName: "", email: "", password: "", confirmPassword: "" });
                authContext.setAuthUser('loading');
                history.push(ROUTES.RECIPE_LIST);
            })
            .catch(error => {
                console.log(error);
            })
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign up
                </Typography>
                <form className={classes.form} onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="given-name"
                                name="firstName"
                                value={values.firstName}
                                variant="outlined"
                                required
                                fullWidth
                                id="firstName"
                                label="First Name"
                                autoFocus
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                value={values.lastName}
                                id="lastName"
                                label="Last Name"
                                name="lastName"
                                autoComplete="family-name"
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="email"
                                value={values.email}
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                onChange={handleInputChange}
                                error={Boolean(emailError)}
                                helperText={Boolean(emailError) ? emailError : ""}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                name="password"
                                value={values.password}
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                onChange={handleInputChange}
                                error={Boolean(passwordError)}
                                helperText={Boolean(passwordError) ? passwordError : ""}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                name="confirmPassword"
                                value={values.confirmPassword}
                                label="Confirm Password"
                                type="password"
                                id="confirm-password"
                                error={passwordMatchError}
                                helperText={passwordMatchError ? "Passwords don't match!" : ""}
                                onChange={handleInputChange}
                            />
                        </Grid>
                    </Grid>
                    <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        className={classes.googleButton}
                        onClick={handleSignInWithGoogle}
                    >
                        Sign Up with Google!
                    </Button>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Sign Up
                    </Button>
                    <Grid container justify="flex-end">
                        <Grid item>
                            <Link component={RouterLink} to="/sign_in" variant="body2" color='secondary'>
                                Already have an account? Sign in
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Container>
    );
}