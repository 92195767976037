import React, { useState } from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';
import './DateRangePickerStyle.css';

function DateRangePicker(props) {
    const initValues = {
        from: null,
        to: null,
        enteredTo: null, // Keep track of the last day for mouseEnter.
    };
    const [values, setValues] = useState(props.dateRange ? props.dateRange : initValues);

    const isSelectingFirstDay = (from, to, day) => {
        const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
        const isRangeSelected = from && to;
        return !from || isBeforeFirstDay || isRangeSelected;
    }

    const handleDayClick = (day) => {
        const { from, to } = values;
        if (from && to && day >= from && day <= to) {
            handleResetClick();
            return;
        }
        if (isSelectingFirstDay(from, to, day)) {
            setValues({
                from: day,
                to: null,
                enteredTo: null,
            });
        } else {
            setValues({
                ...values,
                to: day,
                enteredTo: day,
            });
            props.setDateRange({from: values.from, to: day});
        }
    }

    const handleDayMouseEnter = (day) => {
        const { from, to } = values;
        if (!isSelectingFirstDay(from, to, day)) {
            setValues({
                ...values,
                enteredTo: day,
            });
        }
    }

    const handleResetClick = () => {
        setValues(initValues);
        props.setDateRange({from: null, to: null});
    }

    return (
        <div>
            <DayPicker
                className="Range"
                numberOfMonths={1}
                fromMonth={values.from}
                selectedDays={{from: values.from, to: values.enteredTo }}
                disabledDays={{before: values.from}}
                modifiers={{start: values.from, end: values.enteredTo}}
                onDayClick={handleDayClick}
                onDayMouseEnter={handleDayMouseEnter}
            />
            <div>
                {!values.from && !values.to && 'Please select the first day.'}
                {values.from && !values.to && 'Please select the last day.'}
                {values.from &&
                    values.to &&
                    `Selected from ${values.from.toLocaleDateString()} to
                ${values.to.toLocaleDateString()}`}{' '}
                {values.from && values.to && (
                    <button className="link" onClick={handleResetClick}>
                        Reset
                    </button>
                )}
            </div>
        </div>
    )
}

export default DateRangePicker;