import * as ROUTES from '../constants/routes';
import Fraction from 'fraction.js';

export const parseIngredients = (ingredients) => {
    ingredients = tokenizeIngredients(ingredients);
    let body = { ingredients: ingredients };
    return fetch(ROUTES.PARSER, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    })
        .then(response => response.json())
        .then(data => {
            return parseResponse(data, body);
        });
}

const cleanTag = (tag) => {
    if(tag === "OTHER" || tag === "OOV" || tag.includes('COMMENT') || tag.includes('RANGE')) {
        return 'clear'
    } else if(tag === "QUANTITY") {
        return 'qty'
    } else if(tag === "UNIT") {
        return 'unit'
    } else if(tag === "NAME") {
        return 'name'
    }
}

const parseResponse = (response, body) => {
    let ingredients = [];
    for (let i = 0; i < body['ingredients'].length; i++) {
        let wordList = body['ingredients'][i];
        let tagList = response[i];
        let tokens = wordList.map((word, i) => [word, cleanTag(tagList[i])]);
        tokens = tokens.filter(token => token[0] !== "");
        ingredients.push(tokens);
    }
    console.log(ingredients)
    let parsed_response = ingredients.map((labelList) => {
        return (
            Object.assign({}, ...labelList.map((label, index) => ({ [index]: label })))
        )
    });
    console.log(parsed_response)
    return parsed_response;
}

export const tokenizeIngredients = (ingredients) => {
    let tokenized = [];
    ingredients.forEach((ingredient, index) => {
        let tokens = ingredient.split(re).filter(token => token !== "");
        tokenized.push(tokens);
    });
    return tokenized;
}

let re = new RegExp('\\s*(\\.{3}|[\\p{L}\\p{N}]+-[\\p{L}\\p{N}]+|[\\p{N}]+\\s[\\p{N}]\\/[\\p{N}]+|[\\p{N}]+\\/[\\p{N}]+|[\\p{N}]+\\.[\\p{N}]+|[\\p{L}]+|[\\p{N}]+|[(),!@#$%^&*:<>?"{}\\|\\[\\]])', 'u');

export const compileLabels = (tokens) => {
    let ingredientArray = [];
    tokens.forEach(tokenList => {
        let ingredient = {
            qty: [],
            unit: [],
            name: [],
            clear: []
        }
        Object.values(tokenList).forEach(word => {
            ingredient[word[1]].push(word[0]);
        });
        delete ingredient.clear;
        ingredientArray.push(ingredient);
    });
    return ingredientArray;
}

export const multiplyQuantity = (quantity, servingSize) => {
    let num = new Fraction(quantity);
    let multiplier = new Fraction(servingSize);
    num = num.mul(multiplier);
    return num.toFraction(true);
}