import * as ROUTES from '../constants/routes';

export const scrapeRecipe = (recipeURL, signal) => {
    let body = { url: recipeURL };
    return fetch(ROUTES.SCRAPER, {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
        signal: signal
    })
        .catch(e => {
            //deal with error
        })
        .then(response => response.json())
        .then(data => {
            if (data.error) {
                return data.error;
            } else {
                let ingredients = data.ingredients.filter(ingredient => ingredient !== "");
                let steps = data.instructions.filter(instruction => (instruction !== " " && instruction !== ""));
                let recipe = {
                    id: null,
                    title: data.name,
                    description: data.description,
                    cuisine: data.cuisine ? data.cuisine : [],
                    category: data.category ? data.category: [],
                    time: "Long",
                    privacy: false,
                    notes: "Imported from: " + data.url,
                    ingredients: ingredients,
                    tokens: [],
                    steps: steps,
                    picture: Boolean(data.image),
                    pictureUrl: Boolean(data.image) ? data.image : null,
                };
                return recipe;
            }
        });
}