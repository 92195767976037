import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import RecipeCard from '../components/RecipeCard';
import BreakpointMasonry from '../components/BreakpointMasonry';
import Skeleton from '@material-ui/lab/Skeleton';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import LinkIcon from '@material-ui/icons/Link';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FirebaseContext, AuthUserContext } from '../firebase';
import * as ROUTES from '../constants/routes';
import * as SCRAPING from '../util/scrapeRecipe';

const useStyles = makeStyles((theme) => ({
	root: {
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
		marginBottom: theme.spacing(2)
	},
	skeletonContainer: {
		marginTop: theme.spacing(2),
	},
	skeleton: {
		margin: theme.spacing(1),
	},
	searchBox: {
		marginTop: theme.spacing(2),
		marginBottom: 0,
	},
	search: {
		width: '100%',
	},
	fab: {
		position: 'fixed',
		[theme.breakpoints.down('sm')]: {
			bottom: theme.spacing(10)
		},
		[theme.breakpoints.up('md')]: {
			bottom: theme.spacing(2)
		},
		right: theme.spacing(2),
	},
	fab2: {
		position: 'fixed',
		[theme.breakpoints.down('sm')]: {
			bottom: theme.spacing(10)
		},
		[theme.breakpoints.up('md')]: {
			bottom: theme.spacing(2)
		},
		right: theme.spacing(10),
	},
}));

function RecipeList() {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [recipes, setRecipes] = useState([]);
	const [search, setSearch] = useState([]);
	const [searchText, setSearchText] = useState("");
	const [open, setOpen] = useState(false);
	const [url, setUrl] = useState("");
	const [error, setError] = useState("");
	const [scraperLoading, setScraperLoading] = useState(false);
	const firebase = useContext(FirebaseContext);
	const authContext = useContext(AuthUserContext);
	const history = useHistory();
	const [lastVisible, setLastVisible] = useState(null);
	const [moreData, setMoreData] = useState(true);
	const theme = useTheme();
	const fullScreenDialog = useMediaQuery(theme.breakpoints.down('sm'));
	let pageLimit = 25;

	useEffect(() => {
		setLoading(true);
		setMoreData(true);
		document.title = "Recipe Keeper"
		let recipesRef = firebase.db.collection('recipes')
			.where('authorUID', '==', authContext.authUser.uid)
			.orderBy('title')
			.limit(pageLimit);
		recipesRef.get()
			.then(querySnapshots => {
				let last = querySnapshots.docs[querySnapshots.docs.length - 1];
				setLastVisible(last);
				if (typeof last === "undefined") {
					setMoreData(false);
				}
				let recipeList = [];
				querySnapshots.forEach(doc => {
					let recipe = doc.data();
					recipe.id = doc.id;
					recipeList.push(recipe);
				});
				setRecipes(recipeList);
				setLoading(false);
			})
			.catch(error => {
				console.log(error);
			});
	}, [firebase.db, authContext.authUser.uid, pageLimit]);

	const fetchMoreData = useCallback(() => {
		if (moreData && (lastVisible !== null || typeof lastVisible !== "undefined")) {
			let recipesRef = firebase.db.collection('recipes')
				.where('authorUID', '==', authContext.authUser.uid)
				.orderBy('title')
				.startAfter(lastVisible)
				.limit(pageLimit);
			recipesRef.get()
				.then(querySnapshots => {
					let last = querySnapshots.docs[querySnapshots.docs.length - 1];
					setLastVisible(last);
					if (typeof last === "undefined") {
						setMoreData(false);
					}
					let recipeList = recipes;
					querySnapshots.forEach(doc => {
						let recipe = doc.data();
						recipe.id = doc.id;
						recipeList.push(recipe);
					});
					
					setRecipes(recipeList);
				})
				.catch(error => {
					console.log(error);
				});
		}
	}, [moreData, lastVisible, recipes, authContext.authUser.uid, firebase.db, pageLimit])

	const loader = useCallback(node => {
		if (node !== null) {
			new IntersectionObserver(
				entries => {
					entries.forEach(entry => {
						if (entry.isIntersecting) {
							fetchMoreData();
						}
					});
				},
				{ threshold: 1 }
			).observe(node);
		}
	}, [fetchMoreData]);

	const deleteRecipeCard = (id) => {
		let filteredRecipes = recipes.filter(recipe => recipe.id !== id);
		setRecipes(filteredRecipes);
	}

	const SkeletonCard = () => (
		<div className={classes.skeletonContainer}>
			<Skeleton className={classes.skeleton} variant='rect' height={140} />
			<Skeleton className={classes.skeleton} variant='rect' />
			<Skeleton className={classes.skeleton} variant='rect' width='60%' />
		</div>
	);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		setScraperLoading(false);
		setError("");
		setUrl("");
	};

	useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;
		if (scraperLoading) {
			SCRAPING.scrapeRecipe(url, signal, { signal })
				.then(data => {
					if (!data.title) {
						setError("Unfortunately this link could not be imported");
						setUrl("");
						setScraperLoading(false);
					} else {
						//route to builder
						setScraperLoading(false);
						setError("Loaded!");
						history.push({
							pathname: ROUTES.RECIPE_BUILDER,
							state: data,
						})
					}
				})
				.catch(e => {
					//deal with error
				});
		}

		return function cleanup() {
			controller.abort()
		}
	}, [scraperLoading, history, url])

	const handleSubmit = () => {
		setScraperLoading(true);
		setError("Loading...");
	}

	return (
		<div className={classes.root}>
			<div className={classes.searchBox} >
				<Autocomplete
					multiple
					freeSolo
					id='search'
					value={search}
					onChange={(event, newValue) => {
						setSearch(newValue.map(word => word.toLowerCase()));
					}}
					inputValue={searchText}
					onInputChange={(event, newInputValue) => {
						setSearchText(newInputValue);
					}}
					options={[]}
					renderInput={(params) => (
						<TextField {...params} variant='outlined' label='Search' />
					)}
				/>
			</div>
			{loading &&
				<BreakpointMasonry>
					<SkeletonCard />
					<SkeletonCard />
					<SkeletonCard />
					<SkeletonCard />
					<SkeletonCard />
					<SkeletonCard />
					<SkeletonCard />
				</BreakpointMasonry>}
			{!loading &&
				<div>
					<BreakpointMasonry>
						{recipes.filter(recipe => search.every(s => recipe.searchable.includes(s.toLowerCase()))).map(
							recipe => <RecipeCard key={recipe.id} {...recipe} delete={deleteRecipeCard} />
						)}

					</BreakpointMasonry>
					{moreData && <div ref={loader}>Loading More...</div>}
				</div>
			}
			<Tooltip title="Write Recipe">
				<Fab className={classes.fab}
					color="secondary"
					aria-label="add"
					component={Link} to={ROUTES.RECIPE_BUILDER}>
					<AddIcon />
				</Fab>
			</Tooltip>
			<Tooltip title="Import Recipe From Link">
				<Fab className={classes.fab2}
					color="primary"
					aria-label="add"
					onClick={handleClickOpen}>
					<LinkIcon />
				</Fab>
			</Tooltip>
			<Dialog
			open={open} 
			onClose={null}
			fullScreen={fullScreenDialog}>
				<DialogTitle id="form-dialog-title">Add Recipe from Link</DialogTitle>
				<DialogContent>
					<DialogContentText>
						We can try to automatically import a recipe, just enter the link below!
          			</DialogContentText>
					<TextField
						autoFocus
						margin="dense"
						id="url"
						label="Recipe Link"
						type="url"
						fullWidth
						value={url}
						onChange={(e) => { setUrl(e.target.value) }}
					/>
					{error && <Typography>{error}</Typography>}
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="secondary">
						Cancel
          		</Button>
					<Button onClick={handleSubmit} color="secondary" disabled={scraperLoading}>
						Submit
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

export default RecipeList;
