import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useParams, Redirect, useHistory, Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import EditIcon from '@material-ui/icons/Edit';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import PublicIcon from '@material-ui/icons/Public';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import { FirebaseContext, AuthUserContext } from '../firebase';
import * as ROUTES from '../constants/routes';
import DefaultImage from '../assets/default.svg';
import * as PARSING from '../util/parseIngredients';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
    },
    notes: {
        padding: theme.spacing(2),
        textAlign: "left",
        whiteSpace: "pre-wrap",
        wordWrap: 'break-word',
    },
    description: {
        whiteSpace: "pre-wrap",
        wordWrap: 'break-word',
    },
    lists: {
        textAlign: "left",
    },
    smallAvatar: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        backgroundColor: theme.palette.type === 'dark' ? theme.palette.secondary.light : theme.palette.primary.dark,
        marginRight: theme.spacing(1),
    },
    steps: {
        display: "flex",
        direction: "row",
        padding: theme.spacing(1),
        textAlign: "left",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    loading: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: theme.spacing(4),
    },
    buttonContainer: {
        textAlign: 'center',
    },
    image: {
        objectFit: 'cover',
        width: '100%',
        height: '100%',
        maxHeight: 200,
    },
    imageContainer: {
        maxHeight: 200,
        //overflow: 'hidden',
    },
    stepsHeader: {
        marginBottom: theme.spacing(2),
    },
    ingredientsHeaderContainer: {
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        flexWrap: 'wrap',
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(1),
        width: '100%',
    },
    ingredientsHeader: {
        flex: 1,
    },
    servingsTextField: {
        //flex: 1,
        marginLeft: theme.spacing(2),
        //width: '80%'
    },
    chips: {
        margin: theme.spacing(1),
        padding: theme.spacing(1),
        fontSize: '1.25rem'
    },
}));

function IngredientList(props) {

    return props.ingredients.map((ingredient, index) => {
        let ingString = ingredient;
        props.labels[index].name.forEach(word => {
            ingString = ingString.replace(word, '<b>' + word + '</b>');
        });
        props.labels[index].unit.forEach(word => {
            ingString = ingString.replace(word, '<b style="color: indianred;">' + word + '</b>');
        });
        props.labels[index].qty.forEach(word => {
            ingString = ingString.replace(word, '<b style="color: indianred;">' + PARSING.multiplyQuantity(word, props.servingSize) + '</b>');
        });
        return <Typography key={"ingredient_" + index} dangerouslySetInnerHTML={{ __html: ingString }}></Typography>
    });
}

function IngredientCheckList(props) {

    return props.ingredients.map((ingredient, index) => {
        let ingString = ingredient;
        props.labels[index].name.forEach(word => {
            ingString = ingString.replace(word, '<b>' + word + '</b>');
        });
        props.labels[index].unit.forEach(word => {
            ingString = ingString.replace(word, '<b style="color: indianred;">' + word + '</b>');
        });
        props.labels[index].qty.forEach(word => {
            ingString = ingString.replace(word, '<b style="color: indianred;">' + PARSING.multiplyQuantity(word, props.servingSize) + '</b>');
        });
        return (
            <div key={"ingredient_" + index} style={
                {
                    display: 'flex',
                    direction: 'row',
                    alignItems: 'center',
                    textDecoration: props.checked[index] ? "line-through" : "",
                    filter: props.checked[index] ? 'contrast(0)' : ""
                }
            }>
                <FormControlLabel
                    control={<Checkbox color='default' checked={props.checked[index]} onChange={(event) => props.onCheck(event, index)} name='check' />}
                    label={<Typography dangerouslySetInnerHTML={{ __html: ingString }} />} />
            </div>
        )
    });
}

function Loading() {
    const classes = useStyles();
    return (
        <div className={classes.loading}>
            <CircularProgress />
        </div>
    );
};

function Error() {
    const authContext = useContext(AuthUserContext);
    return (
        <div>
            {authContext.authUser &&
                <Redirect to={ROUTES.RECIPE_LIST} />
            }
            {!authContext.authUser &&
                <Redirect to={ROUTES.HOME} />
            }
        </div>
    );

};

function RecipeViewer() {
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const { id } = useParams();
    const firebase = useContext(FirebaseContext);
    const authContext = useContext(AuthUserContext);
    const [recipe, setRecipe] = useState(null);
    const [servingSize, setServingSize] = useState("1");
    const [servingSizeText, setServingSizeText] = useState("1");
    const [checked, setChecked] = useState([]);
    const [cooking, setCooking] = useState(false);

    useEffect(() => {
        if (recipe !== null) {
            sessionStorage.setItem(recipe.id, JSON.stringify(checked));
        }
    }, [checked, recipe])

    useEffect(() => {
        window.scrollTo(0, 0);
        let cardRecipe = location.state;
        if (typeof cardRecipe !== 'undefined') {
            setRecipe(cardRecipe);
            if (sessionStorage.getItem(cardRecipe.id)) {
                let checked = JSON.parse(sessionStorage.getItem(cardRecipe.id));
                setChecked(checked);
                if(checked.includes(true)) {
                    setCooking(true);
                }
            } else {
                let checked = new Array(cardRecipe.ingredients.length).fill(false);
                setChecked(checked);
            }
            document.title = cardRecipe.title;
            if (cardRecipe.options) {
                setServingSize(cardRecipe.options.servings.toString());
                setServingSizeText(cardRecipe.options.servings.toString());
            }
        } else if ((id !== null) && (recipe === null)) {
            firebase.db.collection('recipes').doc(id).get()
                .then((doc) => {
                    if (doc.exists) {
                        const loadedRecipe = doc.data();
                        setRecipe(loadedRecipe);
                        if (sessionStorage.getItem(loadedRecipe.id)) {
                            let checked = JSON.parse(sessionStorage.getItem(loadedRecipe.id));
                            setChecked(checked);
                            if(checked.includes(true)) {
                                setCooking(true);
                            }
                        } else {
                            let checked = new Array(loadedRecipe.ingredients.length).fill(false);
                            setChecked(checked);
                        }
                        document.title = loadedRecipe.title;
                    } else {
                        console.log("recipe id not valid/doesn't exist");
                        setRecipe('error');
                    }
                })
                .catch(error => {
                    if (error.code === 'permission-denied') {
                        console.log('permission denied');
                        history.push({
                            pathname: ROUTES.SIGN_IN,
                            state: { from: location }
                        });
                    }
                });
        } else if (recipe === null) {
            //setRecipe(null);
        }
    }, [location, id, firebase.db, history, recipe]);

    const onServingSizeChange = (event) => {
        setServingSizeText(event.target.value);
        if (event.target.value !== "") {
            setServingSize(event.target.value);
        }
        if (event.target.valueAsNumber < 0) {
            setServingSizeText("0");
            setServingSize("0");
        }
    }

    const onCheck = (event, index) => {
        let tempChecked = [...checked];
        tempChecked[index] = !tempChecked[index];
        setChecked(tempChecked);
    }

    if (recipe === 'error') {
        return <Error />
    } else if ((recipe !== null) && (recipe !== 'error')) {
        return (
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <Grid container spacing={1}>
                                <Grid item md={3} xs={12}>
                                    <div className={classes.imageContainer}>
                                        <img src={recipe.picture ? recipe.pictureUrl : DefaultImage} className={classes.image} alt='recipe' />
                                    </div>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Typography variant="h5">{recipe.title}</Typography>
                                    <p />
                                    <Typography variant="subtitle1" className={classes.description}>{recipe.description}</Typography>
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    {recipe.cuisine.map((cuisine, index) => <Chip className={classes.chips} key={"cuisine" + index} label={cuisine} icon={<PublicIcon />} />)}
                                    <br />
                                    {recipe.category.map((category, index) => <Chip className={classes.chips} key={"category" + index} label={category} icon={<RestaurantIcon />} />)}
                                    <br />
                                    <Chip className={classes.chips} label={recipe.time} icon={<HourglassEmptyIcon />} />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <Paper className={classes.paper}>
                            <div className={classes.ingredientsHeaderContainer}>
                                <div className={classes.ingredientsHeader} />
                                <Typography className={classes.ingredientsHeader} variant="h6" onClick={() => setCooking(!cooking)}>Ingredients</Typography>
                                <div className={classes.ingredientsHeader}>
                                    <TextField variant='outlined' size='small'
                                        label='Servings'
                                        value={servingSizeText}
                                        onChange={onServingSizeChange}
                                        className={classes.servingsTextField}
                                        type='number' />
                                </div>
                            </div>
                            <Divider />
                            <p />
                            <div className={classes.lists}>
                                {cooking ?
                                    <IngredientCheckList ingredients={recipe.ingredients} labels={recipe.labels} servingSize={servingSize} checked={checked} onCheck={onCheck} />
                                    :
                                    <IngredientList ingredients={recipe.ingredients} labels={recipe.labels} servingSize={servingSize} />
                                }
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <Paper className={classes.paper}>
                            <Typography className={classes.stepsHeader} variant="h6">Steps</Typography>
                            <Divider />
                            <p />
                            <div>
                                {recipe.steps.map((step, index) =>
                                    <div key={"step_" + index} className={classes.steps}>
                                        <Avatar className={classes.smallAvatar}>{index + 1}</Avatar>
                                        <Typography>{step}</Typography>
                                    </div>
                                )}
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <Typography variant="h6">Notes</Typography>
                            <Divider />
                            <p />
                            <Typography className={classes.notes} variant="body1">{recipe.notes}</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} className={classes.buttonContainer}>
                        {authContext.authUser &&
                            <Button aria-label="edit"
                                component={Link}
                                to={{
                                    pathname: ROUTES.RECIPE_BUILDER,
                                    state: recipe,
                                }}
                                variant="contained"
                                color="primary"
                                endIcon={<EditIcon />}
                            >
                                {authContext.authUser.uid === recipe.authorUID ? 'Edit Recipe' : 'Add to My Account'}
                            </Button>
                        }

                    </Grid>
                </Grid>
            </div>
        )
    } else {
        return <Loading />
    };
}

export default RecipeViewer;
