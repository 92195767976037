
export const fetchMealPlan = async (db, mealplan) => {
    let recipeIDs = [];
    Object.values(mealplan).forEach(day => day.forEach(recipe => recipeIDs.push(recipe.id)));
    recipeIDs = [...new Set(recipeIDs)];
    let i, j, chunks = [], chunksize = 10;
    for (i = 0, j = recipeIDs.length; i < j; i += chunksize) {
        chunks.push(recipeIDs.slice(i, i + chunksize));
    }
    let chunkpromises = chunks.map(chunk => {
        return db.collection('recipes').where('__name__', 'in', chunk).get()
            .then(querySnapshot => {
                let temprecipes = []
                querySnapshot.forEach(doc => temprecipes.push({ ...doc.data(), id: doc.id }));
                return temprecipes
            })
            .catch(error => {
                console.log(error);
                return null;
            });
    });
    return Promise.all(chunkpromises)
    .then(recipearr => recipearr.flat())
    .then(recipes => {
        for (let key in mealplan) {
            mealplan[key].forEach((placeholder, index, arr) => arr[index] = {...arr[index], ...recipes.filter(recipe => recipe.id === placeholder.id)[0]});
        }
        return mealplan;
    });
}