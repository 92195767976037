import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import Chip from '@material-ui/core/Chip';
import DefaultImage from '../assets/default.svg';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
    root: {
        //marginTop: theme.spacing(2),
    },
    card: {
        maxWidth: 350,
        margin: 'auto',
    },
    media: {
        height: 0,
        //paddingTop: '56.25%',
        paddingTop: '45%'
    },
    avatar: {
        backgroundColor: red[500],
    },
    chip: {
        marginLeft: theme.spacing(0.5),
        marginTop: theme.spacing(0.5),
    },
    title: {
        textAlign: 'center',
        marginBottom: theme.spacing(0.5),
    },
    chipContainer: {
        textAlign: 'center',
    },
    moreIcon: {
        marginLeft: 'auto',
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    cardContent: {
        paddingBottom: theme.spacing(0),
        '&:last-child': {
            paddingBottom: theme.spacing(2),
        }
    },
    dropdownCardContent: {
        paddingBottom: theme.spacing(0),
        '&:last-child': {
            paddingBottom: theme.spacing(1),
            paddingTop: 0,
        }
    },
    cardActions: {
        padding: theme.spacing(0.5),
    }
}));

function MealPlannerRecipeCard(props) {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <div className={classes.root}>
            <Card className={classes.card}>
                {props.picture !== false &&
                    <CardMedia
                        className={classes.media}
                        image={props.picture ? props.pictureUrl : DefaultImage}
                        title={props.title}
                    />
                }
                <CardContent className={classes.cardContent}>
                    <Typography className={classes.title} variant="body1" color="textPrimary">
                        {props.title}
                    </Typography>
                    <div className={classes.chipContainer}>
                        {props.time !== "" && <Chip className={classes.chip} size='small' label={props.time} />}
                        {props.cuisine.map((cuisine, index) => <Chip className={classes.chip} size='small' key={"cuisine" + index} label={cuisine} />)}
                        {props.category.map((category, index) => <Chip className={classes.chip} size='small' key={"category" + index} label={category} />)}
                    </div>
                </CardContent>
                {props.enableDropdown &&
                    <React.Fragment>
                        <CardActions disableSpacing className={classes.cardActions}>
                            {props.onDelete &&
                                <IconButton onClick={props.onDelete}>
                                    <DeleteIcon />
                                </IconButton>
                            }
                            <IconButton
                                className={clsx(classes.expand, {
                                    [classes.expandOpen]: expanded,
                                })}
                                onClick={handleExpandClick}
                                aria-expanded={expanded}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </IconButton>
                        </CardActions>
                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                            <CardContent className={classes.dropdownCardContent}>
                                <TextField
                                    variant='outlined'
                                    size='small'
                                    name='servings'
                                    label='Servings'
                                    value={props.options.servings}
                                    onChange={props.onUpdateOptions}
                                    type='number' />
                                <FormControlLabel
                                    name="leftovers"
                                    control={
                                        <Checkbox checked={props.options.leftovers} onChange={props.onUpdateOptions} />
                                    }
                                    label="Leftovers?" />
                            </CardContent>
                        </Collapse>
                    </React.Fragment>
                }
            </Card>
        </div>
    );
}

export default MealPlannerRecipeCard;
