import React, { useState, useContext, useEffect } from 'react';
import { Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import { makeStyles, ThemeProvider, createMuiTheme, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Brightness2Icon from '@material-ui/icons/Brightness2';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import TodayIcon from '@material-ui/icons/Today';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import Home from './pages/Home';
import RecipeList from './pages/RecipeList';
import RecipeBuilder from './pages/RecipeBuilder';
import MealPlanner from './pages/MealPlanner';
import MealPlannerMobile from './pages/MealPlannerMobile';
import MealPlan from './pages/MealPlan';
import MealPlanMobile from './pages/MealPlanMobile';
import RecipeViewer from './pages/RecipeViewer';
import GroceryList from './pages/GroceryList';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import PasswordReset from './pages/PasswordReset';
import { FirebaseContext, AuthUserContext } from './firebase';
import * as ROUTES from './constants/routes';
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import PublicRecipeList from './pages/PublicRecipeList';


const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	title: {
		flexGrow: 1,
	},
	appbar: {
		//background: 'linear-gradient(45deg, #4dd0e1 30%, #009688 90%)', //cyan
		//background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)', //blue
	},
	offset: theme.mixins.toolbar,
	bottomNavContainer: {
		width: '100%',
		top: 'auto',
		bottom: 0,
	},
	bottomNav: {
		//marginBottom: theme.spacing(2),
		//height: '64px',
	},
	bottomNavActions: {
		//paddingTop: theme.spacing(2),
		//paddingBottom: theme.spacing(3),
	},
}));

function App() {

	const classes = useStyles();
	const history = useHistory();
	const location = useLocation();
	const firebase = useContext(FirebaseContext);
	const [dark, setDark] = useState(JSON.parse(localStorage.getItem("theme")));
	const [authUser, setAuthUser] = useState(JSON.parse(localStorage.getItem("authUser")));
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.down('sm'), {noSsr: true});

	const handleThemeChange = () => {
		localStorage.setItem("theme", JSON.stringify(!dark));
		setDark(!dark);
	};

	const darkTheme = createMuiTheme({
		palette: {
			type: "dark",
		},
		overrides: {
			MuiAppBar: {
				root: {
					//background: 'linear-gradient(45deg, #196fb3 30%, #1ba2c2 90%)', //blue
				}
			}
		}
	});

	const lightTheme = createMuiTheme({
		palette: {
			type: "light",
		},
		overrides: {
			MuiAppBar: {
				root: {
					//background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)', //cyan
				}
			}
		}
	});

	const handleSignOut = () => {
		setAnchorEl(null);
		firebase.signOut()
			.then(() => {
				history.push(ROUTES.HOME);
			});
	}

	const handleMenu = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		const releaseAuthListener = firebase.auth.onAuthStateChanged(
			user => {
				localStorage.setItem("authUser", JSON.stringify(authUser));
				setAuthUser(user);
			}
		);

		return (() => {
			releaseAuthListener();
		});
	});

	const showBottomNav = () => {
		let showRouteArray = [ROUTES.PUBLIC_RECIPE_LIST, ROUTES.RECIPE_LIST, ROUTES.MEAL_PLAN, ROUTES.GROCERY_LIST];
		if(showRouteArray.includes(location.pathname)) {
			return true;
		} else {
			return false;
		}
	}

	return (
			<ThemeProvider theme={dark ? darkTheme : lightTheme} >
				<AuthUserContext.Provider value={{ authUser, setAuthUser }}>
					<CssBaseline />
					<div className={classes.root}>
						<AppBar position="sticky" className={classes.appbar}>
							<Toolbar>
								<Typography variant="h6" className={classes.title}>
									Recipe Keeper
              				</Typography>
								{(authUser && !mobile) &&
									<React.Fragment>
										<Button color='inherit' component={Link} to={ROUTES.PUBLIC_RECIPE_LIST}>Public Recipes</Button>
										<Button color='inherit' component={Link} to={ROUTES.RECIPE_LIST}>My Recipes</Button>
										<Button color='inherit' component={Link} to={ROUTES.MEAL_PLAN}>Meal Plan</Button>
										<Button color='inherit' component={Link} to={ROUTES.GROCERY_LIST}>Grocery List</Button>
									</React.Fragment>
								}
								<IconButton color="inherit" onClick={handleThemeChange}>
									{dark ? <Brightness7Icon /> : <Brightness2Icon />}
								</IconButton>
								{authUser &&
									<React.Fragment>
										<IconButton
											onClick={handleMenu}
											color="inherit"
										>
											<AccountCircle />
										</IconButton>
										<Menu
											id="menu-appbar"
											anchorEl={anchorEl}
											anchorOrigin={{
												vertical: 'top',
												horizontal: 'right',
											}}
											keepMounted
											transformOrigin={{
												vertical: 'top',
												horizontal: 'right',
											}}
											open={open}
											onClose={handleClose}
										>
											<MenuItem color="inherit" onClick={handleSignOut}>Sign Out</MenuItem>
										</Menu>
									</React.Fragment>
								}
								{!authUser &&
									<React.Fragment>
										<Button color='inherit' component={Link} to={ROUTES.SIGN_IN}>Sign In</Button>
										<Button color='inherit' component={Link} to={ROUTES.SIGN_UP}>Sign Up</Button>
									</React.Fragment>
								}
							</Toolbar>
						</AppBar>
						{/* <div className={classes.offset} /> */}
						<Switch>
							<PublicRoute path={ROUTES.SIGN_IN}>
								<SignIn />
							</PublicRoute>
							<PublicRoute path={ROUTES.SIGN_UP}>
								<SignUp />
							</PublicRoute>
							<PublicRoute path={ROUTES.PASSWORD_RESET}>
								<PasswordReset />
							</PublicRoute>
							<PrivateRoute path={ROUTES.RECIPE_BUILDER}>
								<RecipeBuilder />
							</PrivateRoute>
							<Route path={ROUTES.PUBLIC_RECIPE_LIST}>
								<PublicRecipeList />
							</Route>
							<PrivateRoute path={ROUTES.RECIPE_LIST}>
								<RecipeList />
							</PrivateRoute>
							<PrivateRoute path={ROUTES.MEAL_PLANNER}>
								{mobile ? <MealPlannerMobile /> : <MealPlanner />}
							</PrivateRoute>
							<PrivateRoute path={ROUTES.MEAL_PLAN}>
								{mobile ? <MealPlanMobile /> : <MealPlan />}
							</PrivateRoute>
							<PrivateRoute path={ROUTES.GROCERY_LIST}>
								<GroceryList />
							</PrivateRoute>
							<Route path={ROUTES.RECIPE_VIEWER}>
								<RecipeViewer />
							</Route>
							<PublicRoute path={ROUTES.HOME}>
								<Home />
							</PublicRoute>
							<Route>
								<Redirect to={ROUTES.HOME} />
							</Route>
						</Switch>
					</div>
					{(mobile && authUser && showBottomNav()) &&
						<React.Fragment>
							<div className={classes.offset} />
							<AppBar position='fixed' className={classes.bottomNavContainer}>
								<BottomNavigation
									className={classes.bottomNav}
									value={location.pathname}
									onChange={(event, newValue) => {
										history.push(newValue);
									}}
									showLabels
								>
									<BottomNavigationAction className={classes.bottomNavActions} label="Public" value={ROUTES.PUBLIC_RECIPE_LIST} icon={<RestaurantIcon />} />
									<BottomNavigationAction className={classes.bottomNavActions} label="My Recipes" value={ROUTES.RECIPE_LIST} icon={<RestaurantIcon />} />
									<BottomNavigationAction className={classes.bottomNavActions} label="Meal Plan" value={ROUTES.MEAL_PLAN} icon={<TodayIcon />} />
									<BottomNavigationAction className={classes.bottomNavActions} label="Grocery List" value={ROUTES.GROCERY_LIST} icon={<ShoppingCartIcon />} />
								</BottomNavigation>
							</AppBar>
						</React.Fragment>
					}
				</AuthUserContext.Provider>
			</ThemeProvider >
	);
}

export default App;
