import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import * as PARSING from '../util/parseIngredients';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		margin: theme.spacing(2),
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: theme.palette.text.primary,
	},
	listTextfields: {
		width: '100%',
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	tagButtonGroup: {
		margin: theme.spacing(1),
		padding: theme.spacing(1),
	},
	ingredientItem: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'inline',
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	qtyButton: {
		color: 'white',
		backgroundColor: red[900],
		'&:hover': {
			backgroundColor: red[800],
		},
		"&.Mui-selected": {
			backgroundColor: red[300],

		},
		"&.Mui-selected:hover": {
			backgroundColor: red[200],
		},
	},
	unitButton: {
		color: 'white',
		backgroundColor: blue[900],
		'&:hover': {
			backgroundColor: blue[800],
		},
		"&.Mui-selected": {
			backgroundColor: blue[200],
		},
		"&.Mui-selected:hover": {
			backgroundColor: blue[100],
		},
	},
	nameButton: {
		color: 'white',
		backgroundColor: green[900],
		'&:hover': {
			backgroundColor: green[800],
		},
		"&.Mui-selected": {
			backgroundColor: green[300],
		},
		"&.Mui-selected:hover": {
			backgroundColor: green[200],
		},
	},
	clear: {
		marginRight: 8,
	},
	qty: {
		marginRight: 8,
		backgroundColor: red[300],
	},
	unit: {
		marginRight: 8,
		backgroundColor: blue[300],
	},
	name: {
		marginRight: 8,
		backgroundColor: green[300],
	},
	progress: {
		marginLeft: theme.spacing(1),
	},
}));

function RecipeTagger(props) {
	const classes = useStyles();
	const setParentTokens = props.tokenfn;
	const tokens = props.tokens;
	const [prevIngredients, setPrevIngredients] = useState(props.ingredients);
	const [tag, setTag] = useState("clear");
	const [loading, setLoading] = useState(false);
	const [first, setFirst] = useState(true);

	useEffect(() => {
		//first load when ingredients actually exist
		if ((first && props.ingredients[0] !== "")) {
			setPrevIngredients(props.ingredients);
			setFirst(false);
		}

	}, [props.ingredients, first])

	useEffect(() => {
		//only tokenizes if ingredients have changed
		if ((prevIngredients !== props.ingredients && props.tagging) || tokens.length === 0) {
			setPrevIngredients(props.ingredients);
			let tempTokenList = PARSING.tokenizeIngredients(props.ingredients);
			let tempTokens = tempTokenList.map(tlist => {
				return (
					Object.assign({}, ...tlist.map((token, index) => ({ [index]: [token, 'clear'] })))
				)
			});
			setParentTokens(tempTokens);
		}
	}, [props.tagging, prevIngredients, props.ingredients, setParentTokens, tokens])

	const selectTag = (event, value) => {
		if (value !== null) {
			setTag(value);
		}
	}

	const onClickToken = (event) => {
		let ids = event.target.id.split("_");
		let tempTokens = [...tokens];
		let tempTag = tempTokens[ids[0]][ids[1]][1];
		if (tempTag === tag) {
			tempTokens[ids[0]][ids[1]][1] = 'clear';
		} else {
			tempTokens[ids[0]][ids[1]][1] = tag;
		}
		setParentTokens(tempTokens);
	}

	const autoTag = () => {
		if (props.ingredients[0] !== "") {
			setLoading(true);
			PARSING.parseIngredients(props.ingredients).then(data => {
				setParentTokens(data);
				setLoading(false);
			});
		}
	}

	return (
		<Paper className={classes.paper}>
			<Typography onClick={props.toggleTagging}>Ingredient Tagger</Typography>
			<ToggleButtonGroup
				value={tag}
				exclusive
				className={classes.tagButtonGroup}
				onChange={(event, value) => selectTag(event, value)}>
				<ToggleButton className={classes.qtyButton} value="qty">
					Qty
							</ToggleButton>
				<ToggleButton className={classes.unitButton} value="unit">
					Unit
							</ToggleButton>
				<ToggleButton className={classes.nameButton} value="name">
					Name
							</ToggleButton>
				<ToggleButton value="clear">
					Clear
							</ToggleButton>
			</ToggleButtonGroup>
			<Button variant='contained' color='primary' onClick={autoTag}>Auto Tag {loading && <CircularProgress className={classes.progress} size={16} color='secondary' />} </Button>
			{tokens.map((ingredient, index) =>
				<div key={index}>
					<Divider />
					<div className={classes.ingredientItem}>
						{Object.values(ingredient).map((token, ti) =>
							<Typography
								variant='h6'
								key={index + "_" + ti}
								id={index + "_" + ti}
								onClick={onClickToken}
								className={classes[token[1]]}>
								{token[0]}
							</Typography>
						)}
					</div>
					<Divider />
				</div>
			)}
			<p />
		</Paper>
	);
}

export default RecipeTagger;
