import React, { useState, useContext, useEffect, createRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import Avatar from '@material-ui/core/Avatar';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FirebaseContext, AuthUserContext } from '../firebase';
import * as ROUTES from '../constants/routes';
import * as PARSING from '../util/parseIngredients';
import DefaultImage from '../assets/default.svg';
import RecipeTagger from '../components/RecipeTagger';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		margin: theme.spacing(2),
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: theme.palette.text.primary,
	},
	listTextfields: {
		width: '100%',
	},
	topTextfields: {
		margin: theme.spacing(1),
		width: '100%',
	},
	buttonContainer: {
		textAlign: 'center',
	},
	notes: {
		width: '100%',
	},
	smallAvatar: {
		width: theme.spacing(3),
		height: theme.spacing(3),
		backgroundColor: theme.palette.type === 'dark' ? theme.palette.secondary.dark : theme.palette.primary.dark,
	},
	buttonGroup: {
		marginTop: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	buttonGroup2: {
		marginTop: theme.spacing(1),
		marginLeft: theme.spacing(1),
	},
	uploadInput: {
		display: 'none',
	},
	imageContainer: {
		maxHeight: 200,
	},
	image: {
		objectFit: 'cover',
		width: '100%',
		height: '100%',
		maxHeight: 200,
	}
}));

function RecipeBuilder() {
	const classes = useStyles();
	const firebase = useContext(FirebaseContext);
	const authContext = useContext(AuthUserContext);
	const location = useLocation();
	const history = useHistory();
	//new state
	const initialState = {
		id: null,
		title: "",
		description: "",
		cuisine: [],
		category: [],
		time: "Long",
		privacy: false,
		notes: "",
		ingredients: [""],
		tokens: [],
		steps: [""],
		picture: false,
		pictureUrl: null,
	};
	const [recipe, setRecipe] = useState(initialState);
	const [cuisineText, setCuisineText] = useState("");
	const [categoryText, setCategoryText] = useState("");
	//old state
	const [picture, setPicture] = useState(null);
	const [pictureUrl, setPictureUrl] = useState(null);
	//new stuff
	const [tagging, setTagging] = useState(false);
	const [ingredientRefs, setIngredientRefs] = useState(() => {
		let newRef = createRef(null);
		return { refList: [newRef], newest: newRef }
	});
	const [stepsRefs, setStepsRefs] = useState(() => {
		let newRef = createRef(null);
		return { refList: [newRef], newest: newRef }
	});

	//loads data passed from card or parser
	useEffect(() => {
		window.scrollTo(0, 0);
		let passedRecipe = location.state;
		if (typeof passedRecipe !== 'undefined') {
			if (passedRecipe.id !== null) {
				//data was loaded from card
				setRecipe({ ...passedRecipe });
			} else {
				//data was loaded from parser
				setRecipe({ ...passedRecipe });
			}
			if (passedRecipe.picture) {
				setPictureUrl(passedRecipe.pictureUrl);
			}
			let tempIngredientRefs = passedRecipe.ingredients.map(ingredient => createRef(null));
			let tempStepRefs = passedRecipe.steps.map(step => createRef(null));
			setIngredientRefs({refList: tempIngredientRefs, newest: tempIngredientRefs[0]});
			setStepsRefs({refList: tempStepRefs, newest: tempStepRefs[0]});
		}
	}, [location.state]);

	//handle data entry
	const onListKeyPress = (event) => {
		if (event.key === "Enter") {
			const index = parseInt(event.target.id.split("_")[1]);
			let tempList = [...recipe[event.target.name]];
			tempList.splice(index + 1, 0, "");
			setRecipe({ ...recipe, [event.target.name]: tempList });
			if (event.target.name === 'ingredients') {
				let tempIngredientRefs = [...ingredientRefs.refList];
				let newRef = createRef(null);
				tempIngredientRefs.splice(index + 1, 0, newRef);
				setIngredientRefs({ refList: tempIngredientRefs, newest: newRef });
			} else if (event.target.name === 'steps') {
				let tempStepRefs = [...stepsRefs.refList];
				let newRef = createRef(null);
				tempStepRefs.splice(index + 1, 0, newRef);
				setStepsRefs({ refList: tempStepRefs, newest: newRef });
			}
		}
	}

	const onListKeyDown = (event) => {
		if (event.key === 'ArrowDown') {
			const index = parseInt(event.target.id.split("_")[1]);
			if (event.target.name === 'ingredients') {
				if (ingredientRefs.refList[index + 1]) {
					ingredientRefs.refList[index + 1].current.focus()
				}
			} else if (event.target.name === 'steps') {
				if (stepsRefs.refList[index + 1]) {
					stepsRefs.refList[index + 1].current.focus()
				}
			}
		} else if (event.key === 'ArrowUp') {
			const index = parseInt(event.target.id.split("_")[1]);
			if (event.target.name === 'ingredients') {
				if (ingredientRefs.refList[index - 1]) {
					ingredientRefs.refList[index - 1].current.focus()
				}
			} else if (event.target.name === 'steps') {
				if (stepsRefs.refList[index - 1]) {
					stepsRefs.refList[index - 1].current.focus()
				}
			}
		}
	}

	useEffect(() => {
		let lastRef = ingredientRefs.newest;
		if (lastRef.current && ingredientRefs.refList.length > 1) {
			lastRef.current.focus();
		}
	}, [ingredientRefs])

	useEffect(() => {
		let lastRef = stepsRefs.newest;
		if (lastRef.current && stepsRefs.refList.length > 1) {
			lastRef.current.focus();
		}
	}, [stepsRefs])

	const handleListChange = (event) => {
		const index = parseInt(event.target.id.split("_")[1]);
		let temp = [...recipe[event.target.name]];
		if (event.target.value !== "\n") {
			temp[index] = event.target.value;
		}
		setRecipe({ ...recipe, [event.target.name]: temp });
	}

	const onListClear = (event) => {
		const index = parseInt(event.currentTarget.id.split("_")[1]);
		let temp = [...recipe[event.currentTarget.name]];
		if (index === 0 && recipe[event.currentTarget.name].length === 1) {
			temp[0] = "";
		} else {
			temp.splice(index, 1);
			if (event.currentTarget.name === 'ingredients') {
				let tempIngredientRefs = [...ingredientRefs.refList];
				tempIngredientRefs.splice(index, 1);
				let nextInd = index === 0 ? index : index - 1;
				setIngredientRefs({ refList: tempIngredientRefs, newest: tempIngredientRefs[nextInd] });
			} else if (event.currentTarget.name === 'steps') {
				let tempStepRefs = [...stepsRefs.refList];
				tempStepRefs.splice(index, 1);
				let nextInd = index === 0 ? index : index - 1;
				setStepsRefs({ refList: tempStepRefs, newest: tempStepRefs[nextInd] });
			}
		}
		setRecipe({ ...recipe, [event.currentTarget.name]: temp });
	}

	const handleToggle = (event, value, src) => {
		if (value !== null) {
			setRecipe({ ...recipe, [src]: value });
		}
	}

	const handleForm = (event) => {
		setRecipe({ ...recipe, [event.target.id]: event.target.value });
	}

	const compileSearchArray = (rec) => {
		let titleWords = rec.title.split(" ");
		titleWords.forEach((word, index, arr) => {
			arr[index] = word.toLowerCase();
		});
		let searchable = [rec.time.toLowerCase(), ...titleWords];
		let cuisine = rec.cuisine;
		cuisine.forEach(word => {
			searchable.push(word.toLowerCase());
		});
		let category = rec.category;
		category.forEach(word => {
			searchable.push(word.toLowerCase());
		});
		let labels = rec.labels;
		labels.forEach(ingredient => {
			searchable.push(...ingredient.name.map(word => word.toLowerCase()));
		});
		rec.searchable = searchable;
		return rec;
	}

	//handle data and image submissions/firebase
	const onSubmit = (event) => {
		let tempRecipe = { ...recipe };
		tempRecipe.labels = PARSING.compileLabels(recipe.tokens);
		tempRecipe = compileSearchArray(tempRecipe);
		let data = {
			...tempRecipe,
			author: authContext.authUser.displayName,
			authorUID: authContext.authUser.uid,
		};
		if (data.id) {
			updateRecipe(data);
		} else {
			uploadRecipe(data);
		}
	}

	const uploadRecipe = (data) => {
		firebase.db.collection('recipes').add(data)
			.then(docref => {
				if (picture) {
					uploadImage(docref.id);
				} else {
					history.push(ROUTES.RECIPE_LIST);
				}
			})
			.catch(error => {
				console.log(error);
			});
	}

	const updateRecipe = (data) => {
		firebase.db.collection('recipes').doc(data.id).set(data)
			.then(() => {
				console.log('updated successfully');
				if (picture) {
					uploadImage(data.id)
				} else {
					history.push(ROUTES.RECIPE_LIST);
				}
			})
			.catch(error => {
				console.log(error);
			});
	}

	const handleImageSelect = (event) => {
		if (event.target.files[0]) {
			setPicture(event.target.files[0]);
			setRecipe({ ...recipe, picture: true });
			setPictureUrl(URL.createObjectURL(event.target.files[0]));
			setRecipe({ ...recipe, picture: true });
		}
	}

	const uploadImage = (id) => {
		let metadata = {
			contentType: picture.type,
			cacheControl: 'public,max-age=4000',
		}
		let imageRef = firebase.storage.ref().child('recipe_images/' + id);
		imageRef.put(picture, metadata)
			.then(snapshot => {
				snapshot.ref.getDownloadURL()
					.then(url => {
						firebase.db.collection('recipes').doc(id).update({
							pictureUrl: url
						})
							.then(() => {
								history.push(ROUTES.RECIPE_LIST);
							})
							.catch(error => {
								console.log(error);
							})
					})
					.catch(error => {
						console.log(error);
					});
			})
			.catch(error => {
				console.log(error);
			});
	}

	const toggleTagging = () => {
		if (tagging) {
			setTagging(false);
		} else {
			setTagging(true);
		}
	}

	const setIngredientTokens = (tokens) => {
		setRecipe({ ...recipe, tokens: tokens })
	}

	return (
		<div className={classes.root}>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Paper className={classes.paper}>
						<Grid container spacing={1}>
							<Grid item md={3} xs={12}>
								<input
									accept='image/*'
									className={classes.uploadInput}
									id='image-file-button'
									type="file"
									onChange={handleImageSelect}
								/>
								<label htmlFor='image-file-button'>
									<Button component="span" className={classes.imageContainer}>
										<img src={recipe.picture ? pictureUrl : DefaultImage} className={classes.image} alt='recipe' />
									</Button>
								</label>
							</Grid>
							<Grid item md={6} xs={12}>
								<TextField
									id="title"
									label="Recipe Title"
									value={recipe.title}
									onChange={handleForm}
									className={classes.topTextfields}
									variant="outlined" />
								<TextField
									id="description"
									multiline
									rows={2}
									value={recipe.description}
									onChange={handleForm}
									label="Description"
									className={classes.topTextfields}
									variant="outlined" />
							</Grid>
							<Grid item md={3} xs={12}>
								<Autocomplete
									id="cuisine"
									multiple
									freeSolo
									value={recipe.cuisine}
									inputValue={cuisineText}
									onChange={(event, newValue) => { setRecipe({ ...recipe, cuisine: newValue }) }}
									onInputChange={(event, newInputValue) => { setCuisineText(newInputValue) }}
									options={[]}
									renderInput={(params) => (
										<TextField {...params} label="Cuisine" variant="outlined" className={classes.topTextfields} />
									)}
								/>
								<Autocomplete
									id="category"
									multiple
									freeSolo
									value={recipe.category}
									inputValue={categoryText}
									onChange={(event, newValue) => { setRecipe({ ...recipe, category: newValue }) }}
									onInputChange={(event, newInputValue) => { setCategoryText(newInputValue) }}
									options={[]}
									renderInput={(params) => (
										<TextField {...params} label="Category" variant="outlined" className={classes.topTextfields} />
									)}
								/>
								<ToggleButtonGroup
									value={recipe.time}
									exclusive
									className={classes.buttonGroup}
									onChange={(event, value) => handleToggle(event, value, 'time')}>
									<ToggleButton value="Quick">
										Quick
										<HourglassEmptyIcon />
									</ToggleButton>
									<ToggleButton value="Long">
										Long
										<HourglassFullIcon />
									</ToggleButton>
								</ToggleButtonGroup>
								<ToggleButtonGroup
									value={recipe.privacy}
									exclusive
									className={classes.buttonGroup2}
									onChange={(event, value) => handleToggle(event, value, 'privacy')}>
									<ToggleButton value={false}>
										Public
										<LockOpenIcon />
									</ToggleButton>
									<ToggleButton value={true}>
										Private
										<LockIcon />
									</ToggleButton>
								</ToggleButtonGroup>
							</Grid>
						</Grid>

					</Paper>
				</Grid>
				<Grid item sm={6} xs={12}>
					<div style={{ display: tagging ? 'none' : 'block' }}>
						<Paper className={classes.paper}>
							<Typography onClick={toggleTagging}>Ingredients</Typography>
							{recipe.ingredients.map((ingredient, index) =>
								<div key={index}>
									<Divider />
									<TextField
										className={classes.listTextfields}
										id={"ingredient_" + index}
										name="ingredients"
										key={index}
										value={recipe.ingredients[index]}
										onChange={handleListChange}
										onKeyPress={onListKeyPress}
										onKeyDown={onListKeyDown}
										inputProps={{ autoComplete: 'off' }}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														name='ingredients'
														id={"ingredient_" + index}
														onClick={onListClear}>
														<ClearIcon />
													</IconButton>
												</InputAdornment>
											)
										}}
										inputRef={ingredientRefs.refList[index]}
									/>
									<Divider />
								</div>
							)}
						</Paper>
					</div>
					<div style={{ display: tagging ? 'block' : 'none' }}>
						<RecipeTagger ingredients={recipe.ingredients} tagging={tagging} toggleTagging={toggleTagging} tokens={recipe.tokens} tokenfn={setIngredientTokens} />
					</div>
				</Grid>
				<Grid item sm={6} xs={12}>
					<Paper className={classes.paper}>
						<Typography>Steps</Typography>
						{recipe.steps.map((step, index) =>
							<div key={index}>
								<Divider />
								<TextField
									className={classes.listTextfields}
									id={"step_" + index}
									name="steps"
									key={index}
									value={recipe.steps[index]}
									onChange={handleListChange}
									onKeyPress={onListKeyPress}
									onKeyDown={onListKeyDown}
									multiline
									inputProps={{ autoComplete: 'off' }}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<Avatar className={classes.smallAvatar}>{index + 1}</Avatar>
											</InputAdornment>
										),
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													name='steps'
													id={"step_" + index}
													onClick={onListClear}>
													<ClearIcon />
												</IconButton>
											</InputAdornment>
										)
									}}
									inputRef={stepsRefs.refList[index]}
								/>
								<Divider />
							</div>
						)}
					</Paper>
				</Grid>
				<Grid item xs={12}>
					<Paper className={classes.paper}>
						<TextField
							id="notes"
							value={recipe.notes}
							onChange={handleForm}
							multiline
							className={classes.notes}
							rows={4}
							variant="outlined"
							label="Notes" />
					</Paper>
				</Grid>
				<Grid item xs={12} className={classes.buttonContainer}>
					<Button
						variant="contained"
						className={classes.button}
						color="primary"
						endIcon={<ArrowUpwardIcon />}
						onClick={onSubmit}>
						Submit
					</Button>
				</Grid>
			</Grid>
		</div>
	);
}

export default RecipeBuilder;
