import React, { useState, useEffect, createRef, useContext, useRef } from 'react';
import { Prompt } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Paper from '@material-ui/core/Paper';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import TodayIcon from '@material-ui/icons/Today';
import BackupIcon from '@material-ui/icons/Backup';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import StarIcon from '@material-ui/icons/Star';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FirebaseContext, AuthUserContext } from '../firebase';
import * as GENERATE from '../util/generateGroceryList';
import { fetchMealPlan } from '../util/fetchMealPlan';
import * as _ from 'lodash';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    paper: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.primary,
    },
    headerDiv: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    header: {
        flex: 1,
        textAlign: 'left',
    },
    listTextfields: {
        width: '100%',
    },
    checkedListTextfields: {
        width: '100%',
        textDecoration: 'line-through',
    },
    headerTextFields: {
        width: '100%',
        color: 'green',
    },
    checkbox: {

    }
}));

function GroceryList() {
    const classes = useStyles();
    const firebase = useContext(FirebaseContext);
    const authContext = useContext(AuthUserContext);
    const [loading, setLoading] = useState(false);
    const [groceries, setGroceries] = useState([{ item: "", ref: createRef(null), checked: false, recipes: [] }]);
    const [checkedGroceries, setCheckedGroceries] = useState([]);
    const [newestRef, setNewestRef] = useState(null);
    const [collapseOpen, setCollapseOpen] = useState(true);

    //autosaving
    const autoSave = useRef(
        _.debounce((givenGroceries, givenCheckedGroceries) => {
            onSave(givenGroceries, givenCheckedGroceries);
        }, 3000)).current;

    useEffect(() => {
        if (!(groceries.length === 1 && groceries[0].item === "")) {
            setLoading(true);
            autoSave(groceries, checkedGroceries);
        }
    }, [groceries, checkedGroceries]);

    //initial data load
    useEffect(() => {
        window.scrollTo(0, 0);
        setLoading(true);
        document.title = "Grocery List"
        let userRef = firebase.db.collection('users').doc(`${authContext.authUser.uid}`);
        userRef.get()
            .then(doc => {
                if (doc.exists) {
                    let data = doc.data();
                    if (data.grocerylist && data.checkedgrocerylist) {
                        let grocerylist = data.grocerylist.map(grocery => { grocery.ref = createRef(null); return grocery });
                        let checkedgrocerylist = data.checkedgrocerylist;
                        setGroceries(grocerylist);
                        setCheckedGroceries(checkedgrocerylist)
                        setLoading(false)
                        return grocerylist;
                    } else {
                        throw new Error('no grocerylist');
                    }
                } else {
                    //no meal plan available
                    throw new Error('no grocerylist');
                }
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
            });
    }, [firebase.db, authContext.authUser.uid]);

    //manual save function (used in autosave also)
    const onSave = (givenGroceries, givenCheckedGroceries) => {
        setLoading(true);
        let userRef = firebase.db.collection('users').doc(`${authContext.authUser.uid}`);
        let grocerylist, checkedgrocerylist;
        if (givenGroceries && givenCheckedGroceries) {
            grocerylist = Array.from(givenGroceries).map(grocery => { return { item: grocery.item, checked: grocery.checked, recipes: grocery.recipes } });
            checkedgrocerylist = Array.from(givenCheckedGroceries).map(grocery => { return { item: grocery.item, checked: grocery.checked, recipes: grocery.recipes, index: grocery.index } });
        } else {
            grocerylist = Array.from(groceries).map(grocery => { return { item: grocery.item, checked: grocery.checked, recipes: grocery.recipes } });
            checkedgrocerylist = Array.from(checkedGroceries).map(grocery => { return { item: grocery.item, checked: grocery.checked, recipes: grocery.recipes, index: grocery.index } });
        }
        userRef.update({ grocerylist: grocerylist, checkedgrocerylist: checkedgrocerylist })
            .then(() => {
                console.log('updated groceries');
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
            });
    }

    //manages input references for keyboard nav
    useEffect(() => {
        if (newestRef && newestRef.current) {
            newestRef.current.focus();
        }
    }, [newestRef]);

    //handles pressing enter
    const onListKeyPress = (event) => {
        if (event.key === "Enter") {
            const index = parseInt(event.target.id.split("_")[1]);
            let tempList = [...groceries];
            let newRef = createRef(null);
            tempList.splice(index + 1, 0, { item: "", ref: newRef, checked: false, recipes: [] });
            setGroceries(tempList);
            setNewestRef(newRef);
        }
    }

    //handles keyboard arrow nav
    const onListKeyDown = (event) => {
        if (event.keyCode === 40) {
            event.preventDefault();
            const index = parseInt(event.target.id.split("_")[1]);
            if (groceries[index + 1]) {
                setNewestRef(groceries[index + 1].ref);
            }
        } else if (event.keyCode === 38) {
            event.preventDefault();
            const index = parseInt(event.target.id.split("_")[1]);
            if (groceries[index - 1]) {
                setNewestRef(groceries[index - 1].ref);
            }
        }
    }

    //handles actual typing
    const handleListChange = (event) => {
        const index = parseInt(event.target.id.split("_")[1]);
        let temp = [...groceries];
        if (event.target.value !== "\n") {
            temp[index].item = event.target.value;
        }
        setGroceries(temp);
    }

    //handles individual line item clearing
    const onListClear = (event, checked) => {
        const index = parseInt(event.currentTarget.id.split("_")[1]);
        if (checked) {
            let tempCheckedGroceries = Array.from(checkedGroceries);
            tempCheckedGroceries.splice(index, 1);
            setCheckedGroceries(tempCheckedGroceries);
        } else {
            let tempGroceries = Array.from(groceries);
            let newRef;
            if (index === 0 && groceries.length === 1) {
                tempGroceries[0].item = "";
                tempGroceries[0].ref = createRef(null);
                newRef = tempGroceries[0].ref;
            } else {
                tempGroceries.splice(index, 1);
                let nextInd = index === 0 ? index : index - 1;
                newRef = tempGroceries[nextInd].ref;

            }
            setGroceries(tempGroceries);
            setNewestRef(newRef);
        }
    }

    //handles full list deletion
    const onListDelete = () => {
        if (window.confirm("Are you sure you want to clear your whole grocery list?")) {
            let newRef = createRef(null);
            setGroceries([{ item: "", ref: newRef, checked: false, recipes: [] }]);
            setCheckedGroceries([]);
            setNewestRef(newRef);
        }
    }

    const onCheckGroceryItem = (event, index, checked) => {
        let tempGroceries = Array.from(groceries);
        let tempCheckedGroceries = Array.from(checkedGroceries);
        if (checked) {
            const [removed] = tempCheckedGroceries.splice(index, 1);
            removed.checked = false;
            let oldIndex = removed.index
            delete removed.index;
            tempGroceries.splice(oldIndex, 0, removed);
        } else {
            const [removed] = tempGroceries.splice(index, 1);
            removed.index = index;
            removed.checked = true;
            tempCheckedGroceries.splice(index, 0, removed);
        }
        setGroceries(tempGroceries);
        setCheckedGroceries(tempCheckedGroceries);
    }

    function onDragEnd(result) {
        const { source: droppableSource, destination: droppableDestination } = result;

        // dropped outside the list
        if (!droppableDestination) {
            return;
        }

        const sourceID = droppableSource.droppableId;
        const destID = droppableDestination.droppableId;

        if (sourceID === destID) {
            //Reorder
            const groceryList = Array.from(groceries);
            const [removed] = groceryList.splice(droppableSource.index, 1);
            groceryList.splice(droppableDestination.index, 0, removed);
            setGroceries(groceryList);
            setNewestRef(removed.ref);
        }

    }

    const onDragStart = (result) => {
        setNewestRef(null);
    }

    const onAutoGenerate = () => {
        //load data here
        setLoading(true);
        let userRef = firebase.db.collection('users').doc(`${authContext.authUser.uid}`);
        userRef.get()
            .then(doc => {
                if (doc.exists) {
                    let data = doc.data();
                    if (data.mealplan) {
                        let mealplan = Object.fromEntries(Object.entries(data.mealplan).sort());
                        return mealplan;
                    } else {
                        throw new Error('no meal plan');
                    }
                } else {
                    //no meal plan available
                    throw new Error('no meal plan');
                }
            }).then(placeholderplan => { return fetchMealPlan(firebase.db, placeholderplan) })
            .then(mealplan => {
                GENERATE.generateGroceryList(mealplan).then(arr => {
                    if(groceries.length === 1 && groceries[0].item === "") {
                        setGroceries([...arr.map(grocery => { return { item: grocery.item, recipes: grocery.recipes, ref: createRef(null), checked: false } })]);
                    } else {
                        setGroceries([...groceries, ...arr.map(grocery => { return { item: grocery.item, recipes: grocery.recipes, ref: createRef(null), checked: false } })]);
                    }
                    setLoading(false);
                });
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
            });
    }

    return (
        <div>
            <DragDropContext
                onDragEnd={onDragEnd}
                onDragStart={onDragStart}
            >
                <Paper className={classes.paper}>
                    <div className={classes.headerDiv}>
                        <Typography className={classes.header} variant='h6'>Grocery List</Typography>
                        {!loading &&
                            <IconButton onClick={onSave}>
                                <BackupIcon />
                            </IconButton>
                        }
                        {!loading &&
                            <IconButton onClick={onAutoGenerate}>
                                <TodayIcon />
                            </IconButton>
                        }
                        {loading && <CircularProgress color="secondary" size={36} />}
                        <IconButton onClick={onListDelete}>
                            <DeleteForeverIcon />
                        </IconButton>
                    </div>
                    <Droppable droppableId={'grocerylist'}>
                        {provided => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                {groceries.map((grocery, index) =>
                                    <Draggable draggableId={`groceryitem_${index}`} index={index} key={`groceryitem_${index}`}>
                                        {provided => (
                                            <div ref={provided.innerRef}
                                                {...provided.draggableProps}

                                            >
                                                <Divider />
                                                <TextField
                                                    color='secondary'
                                                    className={classes.listTextfields}
                                                    id={"grocery_" + index}
                                                    name="groceries"
                                                    key={index}
                                                    value={grocery.item}
                                                    onChange={handleListChange}
                                                    onKeyPress={onListKeyPress}
                                                    onKeyDown={onListKeyDown}
                                                    multiline
                                                    inputProps={{ autoComplete: 'off' }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    name='groceries'
                                                                    id={"groceries_" + index}
                                                                    onClick={(event) => onListClear(event, false)}>
                                                                    <ClearIcon />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                        startAdornment: (
                                                            <InputAdornment position='start' >
                                                                <div {...provided.dragHandleProps}>
                                                                    <DragIndicatorIcon />
                                                                </div>
                                                                <Checkbox
                                                                    color='default'
                                                                    className={classes.checkbox}
                                                                    checked={grocery.checked}
                                                                    onChange={(event) => onCheckGroceryItem(event, index, false)}

                                                                />
                                                                {grocery.item[0] === '~' &&
                                                                    <StarIcon color='secondary' />
                                                                }
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                    inputRef={grocery.ref}
                                                />
                                                <Divider />
                                            </div>
                                        )}
                                    </Draggable>
                                )}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                    <br />
                    {checkedGroceries.length > 0 &&
                        <div className={classes.headerDiv} onClick={() => setCollapseOpen(!collapseOpen)}>
                            <Typography className={classes.header} variant='body1'>{`${checkedGroceries.length} checked items`}</Typography>
                            {collapseOpen ? <ExpandLess /> : <ExpandMore />}
                        </div>
                    }
                    <Collapse in={collapseOpen} timeout='auto'>
                        {checkedGroceries.map((grocery, index) =>
                            <div key={`checkedgrocery_${index}`}>
                                <Divider />
                                <TextField
                                    className={classes.checkedListTextfields}
                                    disabled
                                    id={"checkedgrocery_" + index}
                                    name="checkedgroceries"
                                    key={index}
                                    value={grocery.item}
                                    multiline
                                    inputProps={{ autoComplete: 'off' }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    name='checkedgroceries'
                                                    id={"checkedgroceries_" + index}
                                                    onClick={(event) => onListClear(event, true)}>
                                                    <ClearIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                <DragIndicatorIcon visibility={'hidden'} />
                                                <Checkbox
                                                    color='default'
                                                    className={classes.checkbox}
                                                    checked={grocery.checked}
                                                    onChange={(event) => onCheckGroceryItem(event, index, true)}
                                                />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                <Divider />
                            </div>
                        )}
                    </Collapse>
                </Paper>
            </DragDropContext>
            <Prompt when={loading} message="You have unsaved changes, are you sure you want to leave?"/>
        </div>
    );
}

export default GroceryList;
